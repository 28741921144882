import "../Css/VerticalCardCarousel.css";
import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VerticalSlider = () => {
  return (
    <div className="cards">
      <div className="outer">
        <div className="card" style={{ "--delay": -1 }}>
          <div className="testimonial">
            <p>
              Techryders has been a pleasure to deal with over some years. Their
              quality of work, professionalism, knowledge, and follow-through
              have impressed me from day one. Their developer is very responsive
              and quick to address questions. I would highly recommend
              Techryders.
            </p>
            <h3>- Albert</h3>
          </div>
        </div>
        <div className="card" style={{ "--delay": 0 }}>
          <div className="testimonial">
            <p>
              Working with Techryders was a fantastic experience for us. Using
              innovative methods, they provided excellent solutions. They all
              completed their work on schedule and with good quality. It was a
              pleasure working with the team.
            </p>
            <h3>- Micquel</h3>
          </div>
        </div>
        <div className="card" style={{ "--delay": 1 }}>
          <div className="testimonial">
            <p>
              I chose Techryders because of its easy-to-use interface and its
              robust features that enabled us to handle huge volumes of data
              quickly and effectively. I will do business again with them.
            </p>
            <h3>- Sony</h3>
          </div>
        </div>
        <div className="card" style={{ "--delay": 2 }}>
          <div className="testimonial">
            <p>
              Techryders has been a real asset to our IT department - a true
              extension. They have all rolled up their sleeves to meet each
              challenge and opportunity as it arises, and we simply could not
              have done it without them.{" "}
            </p>
            <h3>- Yannick</h3>
          </div>
        </div>
        <div className="card" style={{ "--delay": 2 }}>
          <div className="testimonial">
            <p>
              Their innovative approach has truly transformed our user
              experience and set a new standard for excellence in software
              design.
            </p>
            <h3>- john</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalSlider;
