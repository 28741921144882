import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../ServiceCss/Cloud.css";
import service1 from "../assets/Images/Service 1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DoubleQuote1 from "../assets/Images/Double-quote-1.svg";
import DoubleQuote2 from "../assets/Images/Double-quote-2.svg";
import { Helmet } from "react-helmet";
import HorizontalMarquee from "../Components/HorizontalMarquee";
import ScrollButton from "../Components/ScrollButton";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const Cloud = ({ setProgress }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Cloud</title>
        <meta name="description" content="Cloud Service " />
      </Helmet>

      <div className="cloud-background" style={{ fontFamily: "Varela Round" }}>
        <div className="image-container-cloud">
          <img src={service1} alt="Image" />
        </div>
        <div className="content-cloud">
          <h2
            style={{
              fontWeight: "bolder",
              fontFamily: "Varela Round",
              fontSize: "30px",
            }}
          >
            Cloud
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            Cloud services to speed-up your digital transition for global
            enterprises. Unlock the power of the cloud with our cutting-edge
            cloud solutions.
          </p>
          <button onClick={() => navigate("/contact")} className="about-button">
            Let's Connect <FaArrowRight />
          </button>
        </div>
      </div>

      <div className="Cloud-Cards">
        <div className="left-1">
          <div className="left-2">
            <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
              Maximize Your Business Potential with Our Cloud Solutions
            </h1>
            <br></br>

            <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>
              {" "}
              Advanced cloud services are expertly designed to boost efficiency
              and minimize operational risks across your infrastructure and
              applications. We're committed to delivering superior efficiency
              and availability.
              <br></br>
              <br></br>
              Benefit from our top-tier tools and industry-best practices for a
              seamless, secure transition to a cost-effective and resilient
              cloud. Opt for Public, Private, or Hybrid Cloud to minimize
              downtime and enhance data access. Rely on our robust partnerships
              with industry leaders, including Microsoft Azure, Amazon Web
              Services, and Google Cloud. Whether you're migrating
              infrastructure or building cloud-native applications, we're here
              to elevate your business operations to new heights.
            </p>
          </div>
        </div>
        <div className="right-1">
          <div className="right-2">
            <img src={DoubleQuote1} />
            <br></br>
            <br></br>
            <p style={{ fontFamily: "sans-serif" }}>
              Techryders has consistently delivered exceptional quality,
              professionalism, expertise, and prompt responsiveness throughout
              our years of partnership. Highly recommended.
            </p>
            <br></br>
            <h4>- John</h4>

            <br></br>
            <img src={DoubleQuote2} />
          </div>
        </div>
      </div>

      <div className="cloud-background1" style={{ fontFamily: "Varela Round" }}>
        <div>
          <p className="Cloud-Quote">
            "The ideal cloud solution for your business is to precisely meet
            your unique requirements and strategic objectives."
          </p>
        </div>
      </div>

      <section style={{ marginTop: "100px", marginBottom: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <h2 className="text-dark" style={{ fontFamily: "Varela Round" }}>
                Our{" "}
              </h2>
              <h1 style={{ color: "#fd761b", fontFamily: "Varela Round" }}>
                Offerings <FaArrowRight />
              </h1>

              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                  <p style={{ fontFamily: "sans-serif" }}>
                    Our cloud services leverage cutting-edge methods and tools
                    to harness the full potential of cloud infrastructure,
                    driving innovation and supporting business growth.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4 ",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Architecture Consulting</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Embark on a transformative journey with our Cloud-First
                      Strategy. Elevate your architecture through expert
                      evaluation and benchmarking, aligning your systems with
                      industry standards for unparalleled success. We specialize
                      in optimizing solutions for various cloud environments,
                      ensuring excellence in every scenario. Our cloud-agnostic
                      recommendations drive digital maturity, while our detailed
                      audits provide actionable insights for improvement.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3"></div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                  <div
                    className="p-3 mb-3"
                    style={{
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Security and Governance</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Elevate your cloud security and governance with our
                      cutting-edge solutions. We ensure the protection of your
                      organization's data and compliance. By combining SASE and
                      data-first technologies, we deliver genuine business cloud
                      security, defending against advanced threats and ensuring
                      your vital data remains accessible and secure, no matter
                      where your workforce operates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Modernization</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Modernize your applications in the cloud with our
                      Application Modernization Services. We leverage
                      cutting-edge technologies like containerization,
                      microservices, and serverless computing to revamp your
                      applications. Our approach streamlines operations,
                      enhances scalability, and reduces maintenance overhead.
                      Elevate your applications by migrating to the cloud and
                      leveraging these advanced technologies.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Test Engineering</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      Experience top-notch Cloud Test Engineering. Our expert
                      team specializes in crafting and executing cloud-centric
                      testing strategies, assuring your software's flawless
                      performance and reliability. We tailor our tests to cloud
                      environments, ensuring the highest quality standards. Our
                      cutting-edge testing solutions optimize your cloud
                      software for peak performance, scalability, and ironclad
                      security, resulting in an exceptional user experience.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Manage AWS, Azure, GCP</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      Simplify Your Multi-Cloud Management with Us. Managing
                      AWS, Azure, and GCP can be complex. Our cloud
                      professionals are here to help. We'll assess your needs,
                      research the best solutions, and create a customized
                      multi-cloud strategy for your business, maximizing the
                      potential of these top-tier cloud providers.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Data Lake and Warehouse</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Unleash the Power of Your Data with Cloud Data Lake and
                      Warehouse. Our solution begins by creating a robust Data
                      Lake, where we securely store your diverse data sources at
                      scale. Next, we build a Data Warehouse, transforming this
                      raw data into a goldmine of insights, making it easy to
                      query and analyze. Our team handles it all – from
                      inception to seamless integration, ensuring your data is
                      not only accessible and secure but also finely tuned to
                      drive data-driven, intelligent business decisions
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Service-Page-Work" data-background-title="Process">
        <div class="container container--column">
          <h1 class="section__title-wrap">
            <span class="section__subtitle">How do we work?</span>
          </h1>
          <div class="development-timeline">
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Readiness Assessment and Discovering
                </span>
                <p class="development-timeline__description">
                  Evaluate the organization's existing systems and identify
                  cloud adoption opportunities while assessing the readiness and
                  alignment of current processes with cloud technologies.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Migration Planning
                </span>
                <p class="development-timeline__description">
                  Develop a comprehensive strategy and roadmap for transitioning
                  to the cloud, including resource allocation, security
                  measures, and cost considerations, to ensure a smooth and
                  successful transition.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Migrate & Operationalize
                </span>
                <p class="development-timeline__description">
                  Execute the migration plan, moving applications and data to
                  the cloud environment, and then establish operational
                  procedures, ensuring that the cloud resources are effectively
                  managed and maintained.{" "}
                </p>
              </div>
            </div>
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">Optimize</span>
                <p class="development-timeline__description">
                  Continuously monitor and adjust cloud resources to maximize
                  performance and cost-efficiency, making use of scaling,
                  automation, and cost analysis tools to refine the cloud setup.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Maintenance & Support
                </span>
                <p class="development-timeline__description">
                  Provide ongoing support, monitoring, and maintenance to ensure
                  the reliability, security, and efficiency of cloud-based
                  systems, addressing issues promptly and applying updates as
                  needed.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="background-cloud6" style={{ fontFamily: "Varela Round" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Technologies
        </h1>
        <p
          style={{
            textAlign: "center",
            alignItems: "center",
            fontSize: "20px",
            padding: "20px 50px 0",
            fontFamily: "sans-serif",
          }}
        >
          Our experts offers integrated services and our awesome product help
          you harness the power of technology. Streamline and automate IT.
          Enhance customer experiences, and drive exponential business growth.
        </p>

        <div>
          <HorizontalMarquee />
        </div>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default Cloud;
