import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/LayOut";
import ReactMarkdown from "react-markdown";
import { Link, useNavigate } from "react-router-dom";
import "../Css/Blog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowRight } from "react-icons/fa";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Helmet } from "react-helmet";
import { cardData, availableTags } from "../BlogConfiguration/BlogData";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const Blog = ({ setProgress }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  const navigate = useNavigate();

  const [post, setPost] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showCards, setShowCards] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [filteredByArchive, setFilteredByArchive] = useState(false);
  const [selectedTag, setSelectedTag] = useState(false);
  const [selectedCardContent, setSelectedCardContent] = useState(null);
  const [tag, setTag] = useState(null);

  const filterCardsByTag = (tag) => {
    setSelectedTag(tag);
    setFilteredByArchive(false);
  };

  function CardsByTag(cards, selectedTag) {
    return cards.filter(
      (card) => selectedTag === null || card.tags.includes(selectedTag)
    );
  }

  const loadFile = (fileName) => {
    import(`../MdFiles/${fileName}`).then((res) => {
      fetch(res.default)
        .then((res) => res.text())
        .then((res) => setPost(res));
    });
  };

  const cancel = () => {
    setSelectedFile(null);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (selectedFile) {
      loadFile(selectedFile);
    } else {
      setPost(""); // Clear the post content when no file is selected
    }
  }, [selectedFile]);

  const selectRecentPost = (fileName) => {
    loadFile(fileName);
    setSelectedFile(fileName);
    setFilteredByArchive(false);
    setSelectedTag(false);
  };

  const handleCardClick = (fileName) => {
    loadFile(fileName);
    setSelectedFile(fileName);
    setFilteredByArchive(false);
    setSelectedTag(false);
  };

  // Extract unique months and years for the archive
  const archive = cardData.reduce((acc, card) => {
    const date = new Date(card.date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    if (!acc.some((entry) => entry.year === year && entry.month === month)) {
      acc.push({ year, month });
    }

    return acc;
  }, []);

  archive.sort((a, b) => {
    if (a.year !== b.year) {
      return b.year - a.year;
    }
    return b.month - a.month;
  });

  const formatMonthYear = (year, month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${monthNames[month - 1]} ${year}`;
  };

  const [filteredMonthCards, setFilteredMonthCards] = useState([]);

  const filterCardsByMonthYear = (year, month) => {
    setSelectedYear(year);
    setSelectedMonth(month);
    setFilteredByArchive(true);
    setPost("");

    // Filter and display only cards from the selected month and year
    const monthYearFilteredCards = cardData.filter((card) => {
      const cardDate = new Date(card.date);
      return (
        !isNaN(cardDate) &&
        cardDate.getFullYear() === year &&
        cardDate.getMonth() + 1 === month
      );
    });

    setFilteredMonthCards(monthYearFilteredCards); // Update the filtered cards
  };

  const handleArchiveItemClick = (year, month) => {
    filterCardsByMonthYear(year, month);
  };

  const cardsPerPage = 6; // Number of cards per page

  // Create a state variable to keep track of the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = currentPage * cardsPerPage;

  const sortedCardData = cardData
    .slice()
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  // Select the first 3 posts from the sorted array
  const recentPosts = sortedCardData.slice(0, 3);

  return (
    <Layout>
      <Helmet>
        <title>Blog</title>
        <meta
          name="description"
          content="Techryders Blog Find out the Latest Trends and Latest Blogs "
        />
      </Helmet>
      <div className="blog">
        <h2
          style={{
            color: "white",
            fontFamily: "Varela Round",
            fontWeight: "bold",
          }}
        >
          Blog
        </h2>
        <p
          style={{ fontFamily: "sans-serif", fontSize: "18px", color: "white" }}
        >
          Tech Tales from the Knowledge Vault
        </p>
      </div>

      {selectedFile ? (
        <>
          <div className="Main-Blog-Post">
            <div className="Blog-Post">
              {filteredByArchive ? (
                <>
                  <div className="Blog-card-container">
                    {filteredMonthCards
                      .slice(startIndex, endIndex)
                      .map((filteredCard, index) => (
                        <div
                          key={index}
                          onClick={() => selectRecentPost(filteredCard.file)}
                          className="Blog-card"
                        >
                          <img
                            className="Image-Blogscards2"
                            src={filteredCard.imageSrc}
                            alt={filteredCard.title}
                          />
                          <p className="Card-text">{filteredCard.title}</p>
                          <p className="Card-writer">
                            {filteredCard.writer} {filteredCard.date}
                          </p>
                        </div>
                      ))}
                  </div>
                  {filteredMonthCards.length > cardsPerPage && (
                    <div className="pagination">
                      {currentPage > 1 && (
                        <button onClick={() => setCurrentPage(currentPage - 1)}>
                          Previous
                        </button>
                      )}
                      {endIndex < filteredMonthCards.length && (
                        <button onClick={() => setCurrentPage(currentPage + 1)}>
                          Next
                        </button>
                      )}
                    </div>
                  )}
                </>
              ) : // Render selected card when not filtering by archive
              selectedTag ? (
                <>
                  <div className="Blog-card-container">
                    {cardData
                      .filter(
                        (card) => card.tags && card.tags.includes(selectedTag)
                      )
                      .slice(startIndex, endIndex) // Apply pagination
                      .map((card, index) => (
                        <div
                          key={index}
                          onClick={() => handleCardClick(card.file)}
                          className="Blog-card"
                        >
                          <img
                            className="Image-Blogscards2"
                            src={card.imageSrc}
                            alt={card.title}
                          />
                          <p className="Card-text">{card.title}</p>
                          <p className="Card-writer">
                            {card.writer} {card.date}
                          </p>
                        </div>
                      ))}
                  </div>
                </>
              ) : selectedFile ? (
                // Render selected card when not filtering by archive or tags
                <>
                  <img
                    src={
                      cardData.find((card) => card.file === selectedFile)
                        .imageSrc
                    }
                    alt="Selected Card"
                  />

                  <ReactMarkdown>{post}</ReactMarkdown>
                  <button className="about-button" onClick={cancel}>
                    <FontAwesomeIcon icon={faArrowLeftLong} /> Blog
                  </button>
                </>
              ) : null}
            </div>
            <div className="Blog-Content">
              <div className="Recent-Blog">
                <h5>Recent Blog Posts</h5>
                <ul>
                  {recentPosts.map((recentPost, index) => (
                    <li key={index}>
                      <div
                        className="RecentBlog"
                        onClick={() => selectRecentPost(recentPost.file)}
                      >
                        <div className="RecentBlogImage">
                          <img
                            src={recentPost.imageSrc}
                            alt={recentPost.title}
                          />
                        </div>
                        <div className="RecentBlogText">
                          <h6>{recentPost.title}</h6>
                          <p className="RecentBlogDate">{recentPost.date}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="Blog-archive">
                <h5>Archive</h5>
                <ul>
                  {archive.map((entry, index) => (
                    <li
                      key={index}
                      onClick={() =>
                        handleArchiveItemClick(entry.year, entry.month)
                      }
                    >
                      {formatMonthYear(entry.year, entry.month)}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="Blog-archive">
                <h5>Tags</h5>
                <ul className="Tag-list">
                  {availableTags.map((tag) => (
                    <li key={tag} onClick={() => filterCardsByTag(tag)}>
                      {tag}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="Blog-Cards">
            <h2
              style={{
                fontFamily: "Varela Round",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Filter By Interest
            </h2>
            <div className="Tags">
              <button
                onClick={() => setTag(null)}
                className={tag === null ? "selected" : ""}
              >
                All
              </button>
              {availableTags.map((tagItem) => (
                <button
                  key={tagItem}
                  onClick={() => setTag(tagItem)}
                  className={tag === tagItem ? "selected" : ""}
                >
                  {tagItem}
                </button>
              ))}
            </div>
            <div className="Blog-card-container">
              {CardsByTag(cardData, tag)
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .slice(startIndex, endIndex)
                .map((card, index) => (
                  <div
                    key={index}
                    onClick={() => setSelectedFile(card.file)}
                    className="Blog-card"
                  >
                    <img
                      className="Image-Blogscards1"
                      src={card.imageSrc}
                      alt={card.title}
                    />
                    <p className="Card-text">{card.title}</p>
                    <p className="Card-writer">
                      {card.writer} {card.date}
                    </p>
                  </div>
                ))}
            </div>
          </div>
          {cardData.length > cardsPerPage && (
            <div className="pagination">
              {currentPage > 1 && (
                <button onClick={() => setCurrentPage(currentPage - 1)}>
                  Previous
                </button>
              )}
              {endIndex < cardData.length && (
                <button onClick={() => setCurrentPage(currentPage + 1)}>
                  Next
                </button>
              )}
            </div>
          )}
        </>
      )}

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
    </Layout>
  );
};

export default Blog;
