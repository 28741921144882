import React from "react";
import Marq1 from "../assets/BackgroundImages/1.png";
import Marq2 from "../assets/BackgroundImages/azure.svg";
import Marq3 from "../assets/BackgroundImages/aws.svg";
import Marq4 from "../assets/BackgroundImages/2.svg";
import Marq5 from "../assets/BackgroundImages/8.svg";
import Marq6 from "../assets/BackgroundImages/9.svg";
import Marq7 from "../assets/BackgroundImages/10.svg";
import Marq8 from "../assets/BackgroundImages/11.svg";
import Marq9 from "../assets/BackgroundImages/12.svg";
import Marq10 from "../assets/BackgroundImages/13.svg";
import Marq11 from "../assets/BackgroundImages/14.svg";
import Marq12 from "../assets/BackgroundImages/15.svg";
import Marq13 from "../assets/BackgroundImages/16.svg";
import Marq14 from "../assets/BackgroundImages/17.svg";
import Marq15 from "../assets/BackgroundImages/18.svg";
import Marq16 from "../assets/BackgroundImages/19.svg";
import Marq17 from "../assets/BackgroundImages/20.svg";
import Marq18 from "../assets/BackgroundImages/21.svg";
import Marq19 from "../assets/BackgroundImages/22.svg";
import Marq20 from "../assets/BackgroundImages/23.svg";
import Marq21 from "../assets/BackgroundImages/24.svg";
import Marq22 from "../assets/BackgroundImages/25.svg";
import Marq23 from "../assets/BackgroundImages/26.svg";
import Marq24 from "../assets/BackgroundImages/27.svg";
import Marq25 from "../assets/BackgroundImages/28.svg";

import Marquee from "react-fast-marquee";
import "../Css/HorizontalMarquee.css";

function HorizontalMarquee() {
  return (
    <div>
      <Marquee
        direction="left"
        speed={100}
        delay={5}
        style={{ marginTop: "100px" }}
      >
        <div className="image_wrapper">
          <img src={Marq1} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq2} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq3} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq4} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq5} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq6} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq7} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq8} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq9} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq10} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq11} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq12} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq13} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq14} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq15} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq16} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq17} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq18} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq19} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq20} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq21} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq22} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq23} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq24} alt="" />
        </div>
        <div className="image_wrapper">
          <img src={Marq25} alt="" />
        </div>
      </Marquee>
    </div>
  );
}

export default HorizontalMarquee;
