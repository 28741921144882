import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../Css/Footer.css";
import { FaFacebook, FaFontAwesome, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../assets/Images/Techryders-2.png";
import CountryFlag from "react-country-flag";
import { FiPhoneCall, FiMail } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faFacebookF,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const linkStyle = {
    textDecoration: "none",
    color: "#fff",
  };

  const headingStyle = {
    color: "#fff",
    marginBottom: "10px",
  };

  const handleMouseEnter = (event) => {
    event.target.style.color = "rgb(255, 115, 0)";
  };

  const handleMouseLeave = (event) => {
    event.target.style.color = "#fff";
  };

  return (
    <>
      <footer
        className="image-container"
        style={{ fontFamily: "Varela Round" }}
      >
        <Container fluid className="">
          <Row className="upperRow">
            <Col xs={12} sm={6} md={3}>
              <div>
                <img
                  className="company-logo"
                  src={Logo}
                  alt="Techryders Logo"
                />
              </div>
              <h5
                style={{
                  color: "#fff",
                  paddingLeft: "20px",
                  paddingTop: "30px",
                }}
              >
                Connect with Us
              </h5>
              <div
                className="social-icons"
                style={{ paddingLeft: "20px", marginTop: "15px" }}
              >
                <a href="https://www.facebook.com/techryders" target="_blank">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={{ color: "white", height: "30px" }}
                  />
                </a>{" "}
                <a
                  href="https://www.linkedin.com/company/techryders/"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={{ height: "30px" }}
                  />
                </a>{" "}
                <a href="https://twitter.com/Techryderstech" target="_blank">
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    style={{ color: "#ffffff", height: "30px" }}
                  />
                </a>{" "}
                <a href="https://www.instagram.com/techryders/" target="_blank">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ color: "#e8116f", height: "30px" }}
                  />
                </a>
              </div>
            </Col>
            <Col className="company-footer">
              <h5 style={{ marginBottom: "20px", color: "white" }}>Company</h5>
              <ul className="list-unstyled">
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/about"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    About Us
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/services"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Services
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/careers"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Careers
                  </Link>
                </li>
                {/* <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/blog"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Blog
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="/contact"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </Col>
            <Col className="company-footer-2">
              <h5 style={{ marginBottom: "20px", color: "white" }}>Services</h5>
              <ul className="list-unstyled">
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/services/cloud"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Cloud
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/services/data-analytics"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Data Analytics
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/services/enterprise-solutions"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Enterprise Solutions
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/services/mobile-app-development"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Mobile Application Development
                  </Link>
                </li>
              </ul>
            </Col>
            <Col className="company-footer-3">
              <ul className="list-unstyled">
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/services/productdevelopment"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Product Development
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/services/resource-augmentation"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Resource Augmentation
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/services/qa-testing"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Testing/QA
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/services/ux-ui-design"
                    style={linkStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    UX/UI Design
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="lowerRow">
            <Col>
              <div>
                <div className="country-info" style={{ marginBottom: "10px" }}>
                  <CountryFlag
                    countryCode="IN"
                    svg
                    className="flag-icon"
                    style={{
                      fontSize: "30px",
                      borderRadius: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <span style={headingStyle}>India</span>
                </div>
                <div className="location-info" style={{ marginBottom: "10px" }}>
                  <IoLocationOutline
                    className="icon"
                    style={{
                      fontSize: "25px",
                      marginRight: "10px",
                      color: "white",
                    }}
                  />
                  <span style={headingStyle}>
                    05 Jeevan Basera Apparment,<br></br> Annapurna Mandir,
                    Indore, MP 452009
                  </span>
                </div>
                <div className="phone-info" style={{ marginBottom: "10px" }}>
                  <a style={linkStyle} href="tel:+917314257551">
                    <FiPhoneCall
                      className="icon"
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        color: "white",
                      }}
                    />
                    <span>+91-731-4257551</span>
                  </a>
                </div>
                <div className="email-info" style={{ marginBottom: "10px" }}>
                  <a style={linkStyle} href="mailto:info@Techryders.com">
                    <FiMail
                      className="icon"
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        color: "white",
                      }}
                    />
                    <span>info@Techryders.com</span>
                  </a>
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <div className="country-info" style={{ marginBottom: "10px" }}>
                  <CountryFlag
                    countryCode="US"
                    svg
                    className="flag-icon"
                    style={{
                      fontSize: "30px",
                      borderRadius: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <span style={headingStyle}>USA</span>
                </div>
                <div className="location-info" style={{ marginBottom: "10px" }}>
                  <IoLocationOutline
                    className="icon"
                    style={{
                      fontSize: "25px",
                      marginRight: "10px",
                      color: "white",
                    }}
                  />
                  <span style={headingStyle}>
                    3942, N California Ave 2 E <br></br>Chicago Illinois 60618
                  </span>
                </div>
                <div className="phone-info" style={{ marginBottom: "10px" }}>
                  <a style={linkStyle} href="tel:+1-872-806-5906">
                    <FiPhoneCall
                      className="icon"
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        color: "white",
                      }}
                    />
                    <span>+1-872-806-5906</span>
                  </a>
                </div>
                <div className="email-info" style={{ marginBottom: "10px" }}>
                  <a style={linkStyle} href="mailto:info@Techryders.com">
                    <FiMail
                      className="icon"
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        color: "white",
                      }}
                    />
                    <span>info@Techryders.com</span>
                  </a>
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <div className="country-info" style={{ marginBottom: "10px" }}>
                  <CountryFlag
                    countryCode="NZ"
                    svg
                    className="flag-icon"
                    style={{
                      fontSize: "30px",
                      borderRadius: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <span style={headingStyle}>New Zealand</span>
                </div>
                <div className="location-info" style={{ marginBottom: "10px" }}>
                  <IoLocationOutline
                    className="icon"
                    style={{
                      fontSize: "25px",
                      marginRight: "10px",
                      color: "white",
                    }}
                  />
                  <span style={headingStyle}>
                    52 Stredwick Drive,Torbay, <br></br>Auckland 0630
                  </span>
                </div>
                <div className="phone-info" style={{ marginBottom: "10px" }}>
                  <a style={linkStyle} href="tel:+917314257551">
                    <FiPhoneCall
                      className="icon"
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        color: "white",
                      }}
                    />
                    <span>+64-223-097219</span>
                  </a>
                </div>
                <div className="email-info" style={{ marginBottom: "10px" }}>
                  <a style={linkStyle} href="mailto:info@Techryders.com">
                    <FiMail
                      className="icon"
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        color: "white",
                      }}
                    />
                    <span>info@Techryders.com</span>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      <div className="footer-bar">
        <p className="footer-text">
          &copy; {new Date().getFullYear()} Techryders Pvt Ltd, All rights
          reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
