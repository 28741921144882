import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../ServiceCss/DataAndAnalytics.css";
import "../ServiceCss/Cloud.css";
import service from "../assets/Images/Service 2.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DoubleQuote1 from "../assets/Images/Double-quote-1.svg";
import DoubleQuote2 from "../assets/Images/Double-quote-2.svg";
import { Helmet } from "react-helmet";
import HorizontalMarquee from "../Components/HorizontalMarquee";
import ScrollButton from "../Components/ScrollButton";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const DataAndAnalytics = ({ setProgress }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Data And Analytics</title>
        <meta name="description" content="Data and Analytics Services" />
      </Helmet>
      <div className="Data-background" style={{ fontFamily: "Varela Round" }}>
        <div className="image-container-Data">
          <img src={service} alt="Image" />
        </div>
        <div className="content-Data">
          <h2
            style={{
              fontWeight: "bolder",
              fontFamily: "Varela Round",
              fontSize: "30px",
            }}
          >
            Data And Analytics
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            Turn data into insights, insights into action. Unleash the power of
            data analytics for informed decisions and unrivaled success.
          </p>
          <button onClick={() => navigate("/contact")} className="about-button">
            Let's Connect <FaArrowRight />
          </button>
        </div>
      </div>

      <div className="Cloud-Cards">
        <div className="left-1">
          <div className="left-2">
            <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
              Unlock the Full Power of Your Data with Advanced Analytics
            </h1>
            <br></br>

            <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>
              {" "}
              Data Insights are gateway to transforming your organization into a
              data-driven powerhouse, paving the way for sustainable and organic
              growth. Whether you operate a small startup, a mid-sized
              enterprise, or a large corporation, our team of data experts is
              dedicated to building robust, organized BI solutions.
              <br></br>
              <br></br>
              Harnessing our extensive knowledge in SQL scripting, data
              warehousing, and structured data modeling, we expedite the
              development of a business intelligence ecosystem tailored to your
              specific needs. This ensures that your data becomes a strategic
              asset, enabling you to make informed decisions and drive success
              at every level of your organization.
            </p>
          </div>
        </div>
        <div className="right-1">
          <div className="right-2">
            <img src={DoubleQuote1} />
            <br></br>
            <br></br>
            <p style={{ fontFamily: "sans-serif" }}>
              I selected Techryders due to its robust capabilities, which
              allowed us to efficiently manage substantial data volumes. I plan
              to engage with them again in the future.
            </p>
            <br></br>
            <h4>- Jennifer</h4>
            <br></br>
            <img src={DoubleQuote2} />
          </div>
        </div>
      </div>

      <div className="cloud-background1" style={{ fontFamily: "Varela Round" }}>
        <div>
          <p className="Cloud-Quote">
            "Making sense to your data will enable you to make wise decision and
            realise the full potential of your company."
          </p>
        </div>
      </div>

      <section style={{ marginTop: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <h2 className="text-dark" style={{ fontFamily: "Varela Round" }}>
                Our{" "}
              </h2>
              <h1 style={{ color: "#fd761b", fontFamily: "Varela Round" }}>
                Offerings <FaArrowRight />
              </h1>

              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                  <p style={{ fontFamily: "sans-serif" }}>
                    Our data analytics service employs state-of-the-art methods
                    and tools to extract valuable insights from your data,
                    fueling innovation and fostering business growth.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4 ",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Data Lake and Warehouse</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Dive into a world of limitless data possibilities. Our
                      Data Lake and Warehouse services offer secure, scalable
                      storage for all your data. Seamlessly integrate and access
                      structured and unstructured data for superior analytics
                      and decision-making. It's time to turn raw data into
                      actionable insights!
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3"></div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Data Migration</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Simplify the process of moving your data between systems,
                      platforms, or cloud environments. Our Data Migration
                      services ensure a seamless, secure, and efficient
                      transfer, minimizing downtime and preserving data
                      integrity. Say hello to a hassle-free data journey towards
                      innovation and growth.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Data Modernization</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      Your data is a goldmine waiting to be discovered. Our Data
                      Modernization services embrace the latest technologies to
                      transform your legacy systems. Leverage cloud-native
                      architecture, microservices, and DevOps practices to
                      revamp your data infrastructure. Stay ahead of the curve
                      by adapting technologies like Kubernetes and Docker,
                      enabling your data processes to be agile, innovative, and
                      competitive.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Data Visualization</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      In the world of data, information is only as valuable as
                      your ability to understand and act on it. That's where our
                      Data Visualization services harness advanced tools to turn
                      raw numbers into compelling visuals. Experience your data
                      like never before through interactive dashboards created
                      with tools like Tableau, Power BI, or Cognos etc . Uncover
                      hidden patterns, track performance, and make informed
                      decisions with confidence. Visualization is the key to
                      unlocking your data's true potential with cutting-edge
                      technologies.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Better Decision Making</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      {" "}
                      We empower you with the latest technologies, turning your
                      data into a strategic asset. Leverage machine learning,
                      predictive analytics, and artificial intelligence for
                      deeper insights. Make data-driven decisions using tools
                      like Python, R, or TensorFlow. Respond to market dynamics
                      with agility and drive your business to new heights with
                      unparalleled data intelligence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Service-Page-Work" data-background-title="Process">
        <div class="container container--column">
          <h1 class="section__title-wrap">
            <span class="section__subtitle">How do we work?</span>
          </h1>
          <div class="development-timeline">
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Data Collections
                </span>
                <p class="development-timeline__description">
                  Gathering and assembling relevant data from diverse sources,
                  including databases, files, and APIs, to create a
                  comprehensive dataset for analysis.
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">Data Cleaning</span>
                <p class="development-timeline__description">
                  Identifying and addressing errors, missing values, and
                  inconsistencies in the dataset, ensuring data quality and
                  reliability for accurate analysis.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Data Exploration
                </span>
                <p class="development-timeline__description">
                  Analyzing and visualizing the dataset to understand its
                  characteristics, relationships, and patterns, providing
                  insights for further analysis.{" "}
                </p>
              </div>
            </div>
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Data Preparation
                </span>
                <p class="development-timeline__description">
                  Preprocessing and transforming data by normalizing, scaling,
                  and encoding variables to make it suitable for modeling and
                  analysis.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">Modeling</span>
                <p class="development-timeline__description">
                  Applying statistical and machine learning techniques to build
                  predictive models or extract valuable insights from the
                  preprocessed data, guiding decision-making and
                  problem-solving.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="background-cloud6" style={{ fontFamily: "Varela Round" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Technologies
        </h1>
        <p
          style={{
            textAlign: "center",
            alignItems: "center",
            fontSize: "20px",
            padding: "20px 50px 0",
            fontFamily: "sans-serif",
          }}
        >
          Our experts offers integrated services and our awesome product help
          you harness the power of technology. Streamline and automate IT.
          Enhance customer experiences, and drive exponential business growth.
        </p>

        <div>
          <HorizontalMarquee />
        </div>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default DataAndAnalytics;
