import React from "react";
import "../Css/Careers.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";

const HashTag = () => {
  return (
    <>
      <h1 className="tagline-careers">Follow us to know #LifeAtTechryders</h1>
      <div className="social-icons-careers">
        <a
          href="https://www.linkedin.com/company/techryders/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            className="icon-careers"
            style={{ color: "#0077B5" }}
          />
        </a>
        <a
          href="https://www.instagram.com/techryders/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            className="icon-careers"
            style={{ color: "#E4405F" }}
          />
        </a>
      </div>
    </>
  );
};

export default HashTag;
