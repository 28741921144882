import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../ServiceCss/MobileAppDev.css";
import "../ServiceCss/Cloud.css";
import service from "../assets/Images/Service 4.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DoubleQuote1 from "../assets/Images/Double-quote-1.svg";
import DoubleQuote2 from "../assets/Images/Double-quote-2.svg";
import { Helmet } from "react-helmet";
import HorizontalMarquee from "../Components/HorizontalMarquee";
import ScrollButton from "../Components/ScrollButton";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const MobileAppDev = ({ setProgress }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Mobile Development </title>
        <meta name="description" content="Mobile App Development Services" />
      </Helmet>
      <div className="mobile-background" style={{ fontFamily: "Varela Round" }}>
        <div className="image-container-mobile">
          <img src={service} alt="Image" />
        </div>
        <div className="content-mobile">
          <h2
            style={{
              fontWeight: "bolder",
              fontFamily: "Varela Round",
              fontSize: "30px",
            }}
          >
            Mobile Development
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            Mobilize your business, expand your horizons. We're here to turn
            your innovative ideas into mobile brilliance. Your vision, our
            expertise – crafting the apps that define the future.
          </p>
          <button onClick={() => navigate("/contact")} className="about-button">
            Let's Connect <FaArrowRight />
          </button>
        </div>
      </div>

      <div className="Cloud-Cards">
        <div className="left-1">
          <div className="left-2">
            <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
              Ignite Your Business with Innovative Mobile App Development
            </h1>
            <br></br>

            <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>
              {" "}
              Our mobile application development services are designed to
              empower your business, enhancing its digital presence and reducing
              operational complexities. Whether you're targeting Android or iOS
              platforms, our solutions offer unparalleled efficiency and user
              accessibility.
              <br></br>
              <br></br>
              Using cutting-edge technologies and industry-proven methodologies,
              we guarantee a seamless and secure development process. Your app
              can be customized to meet your specific needs, from feature-rich
              native apps to versatile cross-platform solutions, all aimed at
              reducing development time and maximizing user engagement.
            </p>
          </div>
        </div>
        <div className="right-1">
          <div className="right-2">
            <img src={DoubleQuote1} />
            <br></br>
            <br></br>
            <p style={{ fontFamily: "sans-serif" }}>
              Techryders' mobile development turned our app idea into a reality.
              We'll work with them again
            </p>
            <br></br>
            <h4>- Aila</h4>
            <br></br>
            <img src={DoubleQuote2} />
          </div>
        </div>
      </div>

      <div className="cloud-background1" style={{ fontFamily: "Varela Round" }}>
        <div>
          <p className="Cloud-Quote">
            "An exceptional app seamlessly integrates into your life and
            empowering you on the go."
          </p>
        </div>
      </div>

      <section style={{ marginTop: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <h2 className="text-dark" style={{ fontFamily: "Varela Round" }}>
                Our{" "}
              </h2>
              <h1 style={{ color: "#fd761b", fontFamily: "Varela Round" }}>
                Offerings <FaArrowRight />
              </h1>

              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                  <p style={{ fontFamily: "sans-serif" }}>
                    We offer customized mobile development services to empower
                    our clients to establish their businesses and differentiate
                    themselves in the competitive market.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4 ",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Native Mobile App</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Our mobile app developers can build high-quality native
                      apps for Android, iOS, and Windows systems, perfectly
                      aligned with your business and security requirements.
                      Whether your target audience uses Android, iOS, or Windows
                      devices, our skilled team ensures your app delivers a
                      seamless and secure experience across all major platforms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Cross-Platform Mobile App</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Go big, reach every device effortlessly. Our
                      cross-platform mobile apps harness the power of one
                      codebase to provide a consistent, engaging experience on
                      all major platforms, saving you time and money.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Web to Mobile App Conversion</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      Unleash your web app's potential on mobile. Our conversion
                      experts transform your web solution into a cutting-edge
                      mobile app, expanding your reach and engagement.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>App Design and User Experience</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      First impressions matter - captivate your users. Our
                      expert app designers craft stunning interfaces, ensuring
                      intuitive navigation and a delightful user experience that
                      keeps them coming back for more.
                    </p>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Support and Maintenance</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      Elevate your Mobile App to new heights with our expert
                      support and maintenance services. We breathe life into
                      your existing project, ensuring it stays vibrant and
                      hassle-free. Our team stands ready to enhance performance,
                      address issues, and keep your App at the forefront of
                      innovation. Your success, our commitment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Service-Page-Work" data-background-title="Process">
        <div class="container container--column">
          <h1 class="section__title-wrap">
            <span class="section__subtitle">How do we work?</span>
          </h1>
          <div class="development-timeline">
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Concept and Planning
                </span>
                <p class="development-timeline__description">
                  Define the app's purpose, audience, and core features. Set
                  timelines, budgets, and choose the tech stack.
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">Design</span>
                <p class="development-timeline__description">
                  Create UX/UI wireframes, mockups, and prototypes for an
                  intuitive, responsive, and visually appealing app.
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">Development</span>
                <p class="development-timeline__description">
                  Write code for iOS (Swift/Objective-C), Android (Java/Kotlin),
                  or cross-platform frameworks.
                </p>
              </div>
            </div>
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">Testing</span>
                <p class="development-timeline__description">
                  Ensure bug-free, secure operation with unit and user testing
                  on various platforms and screen sizes.
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Deployment and Maintenance
                </span>
                <p class="development-timeline__description">
                  Launch on app stores, address user feedback, fix bugs, and
                  update for compatibility and performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="background-cloud6" style={{ fontFamily: "Varela Round" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Technologies
        </h1>
        <p
          style={{
            textAlign: "center",
            alignItems: "center",
            fontSize: "20px",
            padding: "20px 50px 0",
            fontFamily: "sans-serif",
          }}
        >
          Our experts offers integrated services and our awesome product help
          you harness the power of technology. Streamline and automate IT.
          Enhance customer experiences, and drive exponential business growth.
        </p>

        {/* <MarqueeCarousel images={imageUrls} imageSize={imageSize} speed={speed} /> */}

        <div>
          <HorizontalMarquee />
        </div>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default MobileAppDev;
