import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../Css/Product.css";
import { Container, Row, Col } from "reactstrap";
import image1 from "../assets/Images/ERP2.png";
import image3 from "../assets/Images/ETL1.png";
import image2 from "../assets/Images/Product1.svg";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import Product1 from "../assets/Images/mobile.png";
import Product2 from "../assets/Images/partner.png";
import Product3 from "../assets/Images/alexa.png";
import Product4 from "../assets/Images/health.png";
import Product5 from "../assets/Images/cart.png";
import Product6 from "../assets/Images/piano.png";
import Product7 from "../assets/Images/joystick.png";
import Product8 from "../assets/Images/education.png";
import ScrollButton from "../Components/ScrollButton";
import { Helmet } from "react-helmet";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const OurProducts = ({ setProgress }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Our Products</title>
        <meta
          name="description"
          content="IT company products ERP ,ETL Tools "
        />
      </Helmet>

      <div
        className="Product-background-1"
        style={{ fontFamily: "Varela Round" }}
      >
        <div className="image-container-Product">
          <img src={image2} alt="Image" />
        </div>
        <div className="content-Product">
          <h2 style={{ fontWeight: "bolder", fontSize: "30px" }}>
            Turning Ideas into Innovations
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            We specialize in turning your ideas into innovative, scalable
            products that drive business growth. From concept to execution, we
            embrace challenges, harness cutting-edge tech, and redefine
            businesses with human-centered design experience with human centred
            design solutions.
          </p>
        </div>
      </div>

      <div
        className="product-background3"
        style={{ fontFamily: "Varela Round" }}
      >
        <div className="content-product3">
          <h2 style={{ fontWeight: "bold" }}>Inventocart</h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "1rem" }}>
            Revolutionize your business operations with our cutting-edge
            integrated systems tailored for production-based or distribution
            businesses. Experience streamlined efficiency and enhanced
            productivity like never before!{" "}
          </p>
          <button
            onClick={() => navigate("/Erp")}
            className="custom-button-Product-1"
          >
            Explore in Detail
          </button>
        </div>
        <div className="image-container-product3">
          <img src={image1} alt="Image" />
        </div>
      </div>

      <div
        className="product-background4"
        style={{ fontFamily: "Varela Round" }}
      >
        <div className="content-product4">
          <h2 style={{ fontWeight: "bold" }}>Cloud Data Quality Framework </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "1rem" }}>
            Unlock the Full Potential of Your Data with Our Cloud-Powered Data
            Quality Framework: Rapid Testing, Seamless Customization, and
            Real-Time Insights for Informed Decision-Making.
          </p>
          <button
            onClick={() => navigate("/Etl")}
            className="custom-button-Product-1"
          >
            Explore in Detail
          </button>
        </div>
        <div className="image-container-product4">
          <img src={image3} alt="Image" />
        </div>
      </div>

      <div className="background-Product-8">
        <Container className="text-center  ">
          <div>
            <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
              Developing Solutions For A Range Of Business Domains.
            </h1>
          </div>
          <Row
            className="mainabout justify-content-center"
            style={{ marginTop: "50px" }}
          >
            {/* First Row */}
            <Row>
              <Col md="3">
                <div className="aboutblock">
                  <div className="process_item">
                    <div className="mainimg">
                      <img src={Product1} alt="Quality Focus" />
                    </div>
                    <h5
                      data-text="01"
                      style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                    >
                      Enterprise Mobility
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="aboutblock">
                  <div className="process_item">
                    <div className="mainimg">
                      <img src={Product2} alt="Superior Service" />
                    </div>
                    <h5
                      data-text="02"
                      style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                    >
                      Social Networks Apps
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="aboutblock">
                  <div className="process_item">
                    <div className="mainimg">
                      <img src={Product3} alt="User Satisfaction" />
                    </div>
                    <h5
                      data-text="03"
                      style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                    >
                      Fintech Apps
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="aboutblock">
                  <div className="process_item">
                    <div className="mainimg">
                      <img src={Product4} alt="Timelines" />
                    </div>
                    <h5
                      data-text="04"
                      style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                    >
                      Health & Fitness Apps
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Second Row */}
            <Row style={{ marginTop: "30px" }}>
              <Col md="3">
                <div className="aboutblock">
                  <div className="process_item">
                    <div className="mainimg">
                      <img src={Product5} alt="Expertise" />
                    </div>
                    <h5
                      data-text="05"
                      style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                    >
                      E-Commerce Mobile Apps
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="aboutblock">
                  <div className="process_item">
                    <div className="mainimg">
                      <img src={Product6} alt="Expertise" />
                    </div>
                    <h5
                      data-text="05"
                      style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                    >
                      Music & Entertainment Apps
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="aboutblock">
                  <div className="process_item">
                    <div className="mainimg">
                      <img src={Product7} alt="Expertise" />
                    </div>
                    <h5
                      data-text="05"
                      style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                    >
                      iGaming and Fantasy Sports Apps
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="aboutblock">
                  <div className="process_item">
                    <div className="mainimg">
                      <img src={Product8} alt="Expertise" />
                    </div>
                    <h5
                      data-text="05"
                      style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                    >
                      Education Apps
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default OurProducts;
