import image1 from "../assets/Images/Blog.png";
import FirstBlog from "../assets/Images/Blog1.gif";
import reactjs from "../assets/Images/Reactjs.png";
import cloudblog from "../assets/Images/CloudBlog.png";
import DataAi from "../assets/Images/DataAnalyticsAi.png";

export const cardData = [
  {
    writer: "-Vaibhav Patel",
    title:
      "Azure DevOps vs. AWS DevOps: Comparing Two Powerhouses in Cloud Development and Operations",
    file: "Collections.md",
    imageSrc: FirstBlog,
    date: "2023-10-30",
    tags: ["Azure DevOps", "AWS DevOps", "Cloud"],
  },
  {
    writer: "-Vaibhav Patel",
    title: "The Key to Modern Web Development",
    file: "Reactjs.md",
    imageSrc: reactjs,
    date: "2023-11-08",
    tags: ["Reactjs", "Frontend"],
  },
  {
    writer: "-Vaibhav Patel",
    title: " A Glimpse into the Future Trends of Cloud Computing",
    file: "Cloud.md",
    imageSrc: cloudblog,
    date: "2023-11-09",
    tags: ["Cloud"],
  },
  {
    writer: "-Vaibhav Patel",
    title: "The Transformative Power of Data",
    file: "DataAnalytics.md",
    imageSrc: DataAi,
    date: "2023-11-15",
    tags: ["DataAnalytics", "AI/ML"],
  },
  {
    writer: "-Vaibhav Patel",
    title: "Dotnet",
    file: "ThirdFile.md",
    imageSrc: image1,
    date: "2023-08-26",
    tags: ["Cloud"],
  },
];

export const availableTags = ["Cloud", "Frontend", "DataAnalytics"];
