import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CardHeader,
} from "reactstrap";
import Layout from "../Components/LayOut";
import CountryFlag from "react-country-flag";
import "../Css/ContactUs.css";
import image1 from "../assets/Images/ContactUs.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import ScrollButton from "../Components/ScrollButton";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import HashTag from "../Components/HashTag";

const ContactUs = ({ setProgress }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const ref = useRef(null);

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validateForm = () => {
    const errors = {};

    if (!name.trim()) {
      errors.name = "Name is required";
    }

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(email)) {
      errors.email = "Invalid email address";
    }

    if (!company.trim()) {
      errors.company = "Company is required";
    }

    if (!subject.trim()) {
      errors.subject = "Subject is required";
    }

    if (!message.trim()) {
      errors.message = "Message is required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    // Simple email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch("https://techryders.com/contactus", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email, company, subject, message }),
        });

        if (response.status === 200) {
          // Handle success on the frontend
          setName("");
          setEmail("");
          setCompany("");
          setSubject("");
          setMessage("");
          setFormSubmitted(true);
          ref.current?.scrollIntoView({ behavior: "smooth" });
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>ContactUs</title>
        <meta name="description" content="Contact Us for any enquiry " />
      </Helmet>

      <div className="Contact-background">
        <div className="image-container-Contact">
          <img src={image1} alt="Image" />
        </div>
        <div ref={ref} className="content-Contact">
          <h1
            style={{
              fontWeight: "bolder",
              fontFamily: "Varela Round",
              fontSize: "30px",
            }}
          >
            Get in Touch
          </h1>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            We're here to help you! Tell us what you're looking for or if you
            need more information about any of our solutions or services, please
            fill out the form and someone from our team will be happy to handle
            your request!
          </p>
        </div>
      </div>

      <Container
        style={{ marginTop: "100px", minHeight: "500px", alignItems: "center" }}
      >
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div className="card-container">
              {formSubmitted ? (
                <div className="text-center">
                  <h1
                    style={{ fontFamily: "Varela Round", fontWeight: "bold" }}
                  >
                    Thanks for reaching out!
                  </h1>
                  <p>
                    Someone from our support team will get back to you shortly.
                  </p>
                </div>
              ) : (
                <Card
                  className="contact-card"
                  style={{ fontFamily: "Varela Round" }}
                >
                  <CardHeader style={{ backgroundColor: "white" }}>
                    <h1 style={{ fontWeight: "bold" }}>Send Us Message </h1>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={handleSubmit}>
                      <FormGroup row>
                        <Label for="name" sm={2}>
                          Name
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            autoComplete="nope"
                            onChange={(e) => setName(e.target.value)}
                            invalid={errors.name}
                          />
                          {errors.name && (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="email" sm={2}>
                          Email
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            autoComplete="nope"
                            onChange={(e) => setEmail(e.target.value)}
                            invalid={errors.email}
                          />
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="company" sm={2}>
                          Company
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            id="company"
                            name="company"
                            value={company}
                            autoComplete="nope"
                            onChange={(e) => setCompany(e.target.value)}
                            invalid={errors.company}
                          />
                          {errors.company && (
                            <div className="invalid-feedback">
                              {errors.company}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="subject" sm={2}>
                          Subject
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            id="subject"
                            name="subject"
                            value={subject}
                            autoComplete="nope"
                            onChange={(e) => setSubject(e.target.value)}
                            invalid={errors.subject}
                          />
                          {errors.subject && (
                            <div className="invalid-feedback">
                              {errors.subject}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="message" sm={2}>
                          Message
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="textarea"
                            id="message"
                            name="message"
                            value={message}
                            autoComplete="nope"
                            onChange={(e) => setMessage(e.target.value)}
                            invalid={errors.message}
                          />
                          {errors.message && (
                            <div className="invalid-feedback">
                              {errors.message}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <div className="text-center">
                        <button
                          onClick={handleSubmit}
                          className="custom-Contact-button-1"
                        >
                          Submit
                        </button>
                        <ToastContainer />
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <div>
        <Card
          className="headQuarters"
          color="primary"
          outline
          style={{ fontFamily: "Varela Round" }}
        >
          <CardBody>
            <Row>
              <Col md={6} className="d-flex align-items-center">
                <div>
                  <h1 style={{ fontWeight: "bold" }}>Headquarters</h1>
                </div>
              </Col>
              <Col md={6} className="d-flex align-items-center">
                <div className="content-wrapper">
                  <CountryFlag
                    countryCode="IN"
                    svg
                    className="flag-icon"
                    style={{
                      fontSize: "80px",
                      borderRadius: "30px",
                      marginRight: "20px",
                    }}
                  />
                  <div>
                    <h1 style={{ fontWeight: "bold" }}>INDIA</h1>
                    <p>
                      05 Jeevan Basera Apparment, Annapurna Mandir <br />
                      Indore, MP 452009 <br />
                    </p>
                    <p>
                      Phone Number <br />
                      +91-731-4257551 <br />
                    </p>
                    <p>
                      Mail <br />
                      info@Techryders.com <br />
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default ContactUs;
