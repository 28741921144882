import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../Css/Services.css";
import image1 from "../assets/Images/Service.svg";
import image2 from "../assets/BackgroundImages/ServiceMain2.svg";
import image3 from "../assets/BackgroundImages/ServiceMain3.svg";
import image4 from "../assets/BackgroundImages/ServiceMain4.svg";
import image5 from "../assets/BackgroundImages/ServiceMain5.svg";
import image6 from "../assets/BackgroundImages/ServiceMain6.svg";
import image7 from "../assets/BackgroundImages/ServiceMain7.svg";
import image8 from "../assets/BackgroundImages/ServiceMain8.svg";
import image9 from "../assets/BackgroundImages/ServiceMain9.svg";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import ScrollButton from "../Components/ScrollButton";
import { Helmet } from "react-helmet";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const Services = ({ setProgress }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 2000);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Services</title>
        <meta name="description" content="Explore All Services we provide" />
      </Helmet>

      <div
        className="Services-background"
        style={{ fontFamily: "Varela Round" }}
      >
        <div className="image-container-Services">
          <img src={image1} alt="Image" />
        </div>
        <div className="content-Services">
          <h2 style={{ fontWeight: "bolder", fontSize: "30px" }}>
            Unlock the Next Stage of Digitalisation
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            We offer a comprehensive range of cutting-edge IT services tailored
            to meet your unique needs. Our team of skilled professionals is
            dedicated to delivering innovative solutions that drive efficiency,
            productivity, and growth for your business.
          </p>
        </div>
      </div>

      <div
        className="background-container"
        style={{ fontFamily: "Varela Round" }}
      >
        <div className="content">
          <h1 style={{ paddingBottom: "30px", fontFamily: "Varela Round" }}>
            Our Commitment to Excellence: Explore Our Offerings
          </h1>
          <p style={{ fontFamily: "sans-serif" }}>
            At Techryders, we are passionately committed to excellence. Our
            service page opens the door to discovering the exceptional solutions
            we offer. We take pride in delivering top-notch services that
            address your unique requirements.
            {/* <br></br>  */}
            Whether you have diverse needs or specific goals, our team of
            experts is wholeheartedly dedicated to surpassing your expectations.
            Discover how our dedication to excellence resonates in every project
            we undertake. We eagerly anticipate the opportunity to serve you and
            demonstrate the enthusiasm we bring to our work.
          </p>
        </div>
      </div>

      <div className="Services-container-1">
        <div className="Services-content-1">
          <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
            Cloud
          </h1>
          <p style={{ fontFamily: "sans-serif" }}>
            Embrace the future with our Cloud Services. We offer end-to-end
            solutions, including cloud first strategy development, migration,
            infrastructure management, security optimization, and data lake
            implementation. With expertise in AWS, Azure, Google Cloud, and
            more, we ensure your data is safeguarded while achieving scalability
            and cost-efficiency in your operations.
          </p>
          <button
            onClick={() => navigate("/services/cloud")}
            className="Services-button-1"
          >
            Know More <FaArrowRight />
          </button>
        </div>
        <div className="Services-image-1">
          <img src={image2} alt="Image" className="image-Services-1" />
        </div>
      </div>

      <div className="Services-container-2">
        <div className="Services-image-2">
          <img src={image3} alt="Image" className="image-Services-2" />
        </div>
        <div className="Services-content-2">
          <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
            Data And Analytics
          </h1>
          <p style={{ fontFamily: "sans-serif" }}>
            Unlock the hidden potential within your data with our Data and
            Analytics services. We provide comprehensive data solutions,
            including data collection, cleansing, processing, advanced
            analytics, and reporting. Our expertise in big data, machine
            learning, and data visualization empowers you to make data-driven
            decisions, gain actionable insights, and maintain a competitive edge
            in your industry.
          </p>
          <button
            onClick={() => navigate("/services/data-analytics")}
            className="Services-button-1"
          >
            Know More <FaArrowRight />
          </button>
        </div>
      </div>

      <div className="Services-container-3">
        <div className="Services-content-3">
          <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
            Enterprise Solution
          </h1>
          <p style={{ fontFamily: "sans-serif" }}>
            Our Enterprise Solutions are the key to streamlining your business
            operations. We offer a suite of services including ERP
            implementation, CRM solutions, custom software development, and
            workflow automation. With a focus on enhancing efficiency, reducing
            operational costs, and ensuring seamless integration, our solutions
            are tailored to meet your specific needs and drive your business
            towards success.
          </p>
          <button
            onClick={() => navigate("/services/enterprise-solutions")}
            className="Services-button-1"
          >
            Know More <FaArrowRight />
          </button>
        </div>
        <div className="Services-image-3">
          <img src={image4} alt="Image" className="image-Services-3" />
        </div>
      </div>

      <div className="Services-container-4">
        <div className="Services-image-4">
          <img src={image5} alt="Image" className="image-Services-4" />
        </div>
        <div className="Services-content-4">
          <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
            Mobile Application Development
          </h1>
          <p style={{ fontFamily: "sans-serif" }}>
            Transform your digital ideas into engaging mobile experiences with
            our Mobile Application Development services. Our comprehensive
            offerings encompass iOS, Android, and cross-platform app
            development. We excel in the complete app lifecycle, from concept to
            deployment, with a strong emphasis on user-centric design,
            performance optimization, and feature-rich functionality, ensuring
            that your mobile app stands out in the market.
          </p>
          <button
            onClick={() => navigate("/services/mobile-app-development")}
            className="Services-button-1"
          >
            Know More <FaArrowRight />
          </button>
        </div>
      </div>

      <div className="Services-container-5">
        <div className="Services-content-5">
          <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
            Product Development
          </h1>
          <p style={{ fontFamily: "sans-serif" }}>
            Realize your vision and take your concepts to market-ready products
            with our Product Development services. Our comprehensive approach
            covers ideation, prototyping, development, and successful product
            launch. We specialize in creating innovative, user-centric solutions
            that are scalable and equipped to captivate your target audience,
            setting your product on a path to success.
          </p>
          <button
            onClick={() => navigate("/services/ProductDevelopment")}
            className="Services-button-1"
          >
            Know More <FaArrowRight />
          </button>
        </div>
        <div className="Services-image-5">
          <img src={image6} alt="Image" className="image-Services-5" />
        </div>
      </div>

      <div className="Services-container-6">
        <div className="Services-image-6">
          <img src={image7} alt="Image" className="image-Services-6" />
        </div>
        <div className="Services-content-6">
          <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
            Resource Augumentation
          </h1>
          <p style={{ fontFamily: "sans-serif" }}>
            Supercharge your team's capabilities with our Resource Augmentation
            services. We provide skilled professionals, including developers,
            designers, and project managers, on-demand. Whether you need
            temporary support or long-term expertise, we seamlessly integrate
            with your in-house teams, ensuring you have the right talent at the
            right time to drive your projects forward.
          </p>
          <button
            onClick={() => navigate("/services/resource-augmentation")}
            className="Services-button-1"
          >
            Know More <FaArrowRight />
          </button>
        </div>
      </div>

      <div className="Services-container-7">
        <div className="Services-content-7">
          <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
            Testing/QA
          </h1>
          <p style={{ fontFamily: "sans-serif" }}>
            Testing is a multifaceted process that demands specialized expertise
            to ensure success. Harness the power of our comprehensive testing
            and quality assurance best practices to ensure the unwavering
            reliability and uncompromised quality of your software applications.
            Our approach not only reduces defects but also prioritizes customer
            satisfaction.
          </p>
          <button
            onClick={() => navigate("/services/qa-testing")}
            className="Services-button-1"
          >
            Know More <FaArrowRight />
          </button>
        </div>
        <div className="Services-image-7">
          <img src={image8} alt="Image" className="image-Services-7" />
        </div>
      </div>

      <div className="Services-container-8">
        <div className="Services-image-8">
          <img src={image9} alt="Image" className="image-Services-8" />
        </div>
        <div className="Services-content-8">
          <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
            UX/UI Design
          </h1>
          <p style={{ fontFamily: "sans-serif" }}>
            Elevate user experiences and boost customer satisfaction through our
            user-centric UX/UI design services, making your digital products
            visually appealing and highly usable. Our professional designers are
            filled with zeal and passion to create websites and mobile
            applications that look and feel awesome.
          </p>
          <button
            onClick={() => navigate("/services/ux-ui-design")}
            className="Services-button-1"
          >
            Know More <FaArrowRight />
          </button>
        </div>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default Services;
