import React from "react";
import "../Css/Aboutus.css";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Suggestion() {
  const navigate = useNavigate();

  return (
    <>
      <div className="about-text-1">
        Do you have an idea to discuss? Come to us, we'd love to hear from you.
      </div>
      <button onClick={() => navigate("/contact")} className="about-button">
        Let's Connect <FaArrowRight /> {/* Add arrow icon */}
      </button>
    </>
  );
}

export default Suggestion;
