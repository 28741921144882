import React, { useEffect, useRef, useState } from "react";
import "../learncss/LearnHome.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay,
  faComment,
  faEnvelope,
  faFaceAngry,
  faStar,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAward,
  faBook,
  faInfinity,
  faLaptopCode,
  faLocationDot,
  faPen,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faJava,
  faReact,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import image1 from "../Assets/11785892_4814041 1.svg";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  Nav,
} from "reactstrap";
import "../learncss/LearnFooter.css";
import "../learncss/LearnNavbar.css";
import { Link } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import ScrollButton from "../Components/LearnScrollButton";
function Home() {
  const ref = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    let isValid = true;
    const newFormErrors = { ...formErrors };

    if (!formData.name.trim()) {
      newFormErrors.name = "Name is required";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newFormErrors.email = "Valid email is required";
      isValid = false;
    }

    const phoneRegex = /^\d{10}$/;
    if (
      !formData.contactNumber.trim() ||
      !phoneRegex.test(formData.contactNumber)
    ) {
      newFormErrors.contactNumber = "Valid contact number is required";
      isValid = false;
    }

    if (!formData.message.trim()) {
      newFormErrors.message = "Message is required";
      isValid = false;
    }

    setFormErrors(newFormErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const { name, email, contactNumber, message } = formData;

      try {
        const response = await fetch("https://techryders.com/contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email, phone: contactNumber, message }),
        });

        if (response.status === 200) {
          setFormData({
            name: "",
            email: "",
            contactNumber: "",
            message: "",
          });
          setFormSubmitted(true);
          setTimeout(() => {
            setFormSubmitted(false);
          }, 20000);
        } else {
          console.error("Form submission failed. Status:", response.status);
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    } else {
      console.log("Form validation failed");
    }
  };
  const handleApply = (e) => {
    e.preventDefault();
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const courses = useRef(null);
  const event = useRef(null);
  const handleCourse = (e) => {
    e.preventDefault();
    courses.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleEvents = (e) => {
    e.preventDefault();
    event.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [isOpen, setIsOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavbarBrandClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <Navbar
        className={scrolling ? "scrolled-navbar" : ""}
        dark
        fixed="top"
        expand="md"
      >
        <NavbarBrand
          tag={Link}
          to="/Learn-IT"
          onClick={handleNavbarBrandClick}
          className="Learnnavbarbrand"
        >
          Learn-IT
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <span onClick={handleCourse} className="Learnnav-item">
              Courses
            </span>

            <span onClick={handleEvents} className="Learnnav-item">
              Events
            </span>

            <span onClick={handleApply} className="Learnnav-item">
              Contact Us
            </span>
          </Nav>
        </Collapse>
      </Navbar>
      <div>
        <div className="section1">
          <h1>
            Embark on your software technology career journey with Learn-IT.
          </h1>
          <button className="button1" onClick={handleApply}>
            Apply Now
          </button>
        </div>
        <div className="section6">
          <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
            Empower Your Future: Comprehensive IT Courses for Transformational
            Growth
          </h1>
          <br></br>
          <p>
            If you're considering a career in the dynamic field of information
            technology (IT), now is an opportune time to take the leap. The
            demand for skilled IT professionals is soaring, and numerous
            companies are actively seeking qualified candidates to fill diverse
            roles.
            <br></br>
            <br></br>
            Explore our comprehensive courses designed to empower you in
            critical IT domains, including Software Development Engineer in Test
            (SDET), Java programming, ReactJS development, and mastering SQL.
            Flexible online learning,our programs cater to your needs. With a
            track record of guiding over 1000 individuals to unlock their full
            potential, we are committed to leading you on a transformative
            journey. Our goal is to help you not only acquire technical skills
            but also to embrace and express your complete self.
          </p>

          <button
            ref={courses}
            style={{ marginTop: "20px" }}
            className="button"
            onClick={handleApply}
          >
            Connect Us
          </button>
        </div>

        <div className="section2">
          <div>
            <div className="Cards">
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  size="xl"
                  style={{ color: "#960e0e", marginBottom: "10px" }}
                  spin
                />
                <h3>SDET</h3>
                <p>
                  Elevate your role from project inception as an SDET,
                  contributing to efficient test automation throughout. This
                  unique position transcends traditional testing, making you a
                  developer with a coding-testing fusion.
                </p>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  size="xl"
                  style={{ color: "#960e0e", marginBottom: "10px" }}
                  spin
                />
                <h3>DevOps</h3>
                <p>
                  Facilitate seamless collaboration between development and
                  operations as a DevOps Engineer. Master continuous integration
                  and deployment, bridging the gap between development and IT
                  operations with efficient and automated workflows.
                </p>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  size="xl"
                  style={{ color: "#960e0e", marginBottom: "10px" }}
                  spin
                />
                <h3>Java</h3>
                <p>
                  Become a proficient Java developer with our comprehensive
                  course. Master the creation of versatile and robust code, from
                  fundamentals to advanced concepts, preparing you for success
                  in the dynamic world of software development.
                </p>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faStar}
                  size="xl"
                  style={{ color: "#960e0e", marginBottom: "10px" }}
                  spin
                />
                <h3>REACT JS</h3>
                <p>
                  Craft dynamic user interfaces with ReactJS in our immersive
                  course. Learn to create modern and responsive web
                  applications, meeting the demands of contemporary users.
                  Unlock the power of ReactJS for interactive and engaging user
                  experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section4">
          <div className="section4-left">
            <h1
              style={{
                marginBottom: "50px",
                fontFamily: "Varela Round",
                fontWeight: "bold",
              }}
            >
              Optimize your learning experience with the finest educational
              tools available.
            </h1>
            <button onClick={handleApply} className="button" type="submit">
              Contact Us
            </button>
          </div>
          <div className="section4-right">
            <div className="live-class-container">
              <div>
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  size="2xl"
                  style={{ color: "#960e0e", marginBottom: "20px" }}
                />
                <h4>Live Classes</h4>
                <p>
                  Engage in real-time interactions with instructors and fellow
                  students, fostering dynamic learning experiences during live
                  classes.
                </p>
              </div>

              <div>
                <FontAwesomeIcon
                  icon={faBook}
                  size="2xl"
                  style={{ color: "#960e0e", marginBottom: "20px" }}
                />
                <h4>Text material</h4>
                <p>
                  Access essential text materials to complement live classes,
                  providing additional resources for comprehensive understanding
                  and review.
                </p>
              </div>
            </div>
            <div className="live-class-container">
              <div>
                <FontAwesomeIcon
                  icon={faPen}
                  size="2xl"
                  style={{ color: "#960e0e", marginBottom: "20px" }}
                />
                <h4>Assessments</h4>
                <p>
                  Benefit from real-time assessments in live classes, gauging
                  your progress and understanding while receiving immediate
                  feedback.
                </p>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faAward}
                  size="2xl"
                  style={{ color: "#960e0e", marginBottom: "20px" }}
                />
                <h4>Certificate awards</h4>
                <p>
                  Upon successful completion of live classes, receive
                  certificates as a testament to your acquired skills and
                  knowledge.
                </p>
              </div>
            </div>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
          style={{ backgroundColor: "rgb(243, 243, 247)" }}
        >
          <path
            fill="#022139"
            fill-opacity="1"
            d="M0,320L1440,96L1440,320L0,320Z"
          ></path>
        </svg>
        <div className="section8">
          <div className="section8-left">
            <h1
              style={{
                fontFamily: "Varela Round",
                fontWeight: "bold",
                color: "white",
              }}
            >
              Hands-On Learning Approach
            </h1>
            <p style={{ color: "white" }}>
              At Learn-IT, we empower students with immersive and in-depth
              software coding learning within a condensed timeframe. Our boot
              camp courses are meticulously crafted to enhance coding
              proficiency for aspiring developers, ensuring they secure
              positions in esteemed organizations. Additionally, we provide
              career coaching support to guide students toward new horizons of
              success.
            </p>
            <div className="figures">
              <div>
                <h1>90%</h1>
                <p>Placement</p>
              </div>
              <div>
                <h1>120k</h1>
                <p>Average Salary</p>
              </div>
              <div>
                <h1 ref={event}>2000+</h1>
                <p>Students</p>
              </div>
            </div>
          </div>
          <div className="section8-right">
            <img src={image1}></img>
          </div>
        </div>
        <div className="section7">
          <h1
            style={{
              fontFamily: "Varela Round",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Upcoming Events
          </h1>
          <div>
            <div className="Cards-3">
              <div>
                <FontAwesomeIcon
                  icon={faLaptopCode}
                  style={{
                    height: "100px",
                    marginBottom: "10px",
                  }}
                />
                <h3>SDET</h3>
                <button className="button" onClick={handleApply}>
                  Register Now
                </button>
              </div>
              <div>
                <div>
                  <FontAwesomeIcon
                    icon={faInfinity}
                    style={{
                      color: "#085ae7",
                      height: "100px",
                      marginBottom: "10px",
                    }}
                  />
                  <h3>DevOps</h3>
                  <button className="button" onClick={handleApply}>
                    Register Now
                  </button>
                </div>
              </div>
              <div>
                <div>
                  <FontAwesomeIcon
                    icon={faJava}
                    size="2xl"
                    style={{
                      color: "#e07a1a",
                      height: "100px",
                      marginBottom: "10px",
                    }}
                  />
                  <h3>Java</h3>
                  <button className="button" onClick={handleApply}>
                    Register Now
                  </button>
                </div>
              </div>
              <div>
                <div>
                  <FontAwesomeIcon
                    icon={faReact}
                    style={{
                      color: "#2fe6e9",
                      height: "100px",
                      marginBottom: "10px",
                    }}
                  />
                  <h3>React Js</h3>
                  <button className="button" onClick={handleApply}>
                    Register Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
          style={{ backgroundColor: "rgb(243, 243, 247)" }}
        >
          <path
            fill="#022139"
            fill-opacity="1"
            d="M0,96L1440,256L1440,320L0,320Z"
          ></path>
        </svg>
        <div className="section5">
          <div>
            <h1
              style={{
                fontFamily: "Varela Round",
                fontWeight: "bold",
                color: "white",
              }}
            >
              Unveiling the Learning Experience Through Your Eyes
            </h1>
            <div className="Cards-2">
              <div>
                <FontAwesomeIcon
                  icon={faComment}
                  size="2xl"
                  beat
                  style={{ color: "#960e0e", marginBottom: "20px" }}
                />

                <p>
                  Opting for Learn-IT's SDET training was a game-changer! The
                  comprehensive program covered technical and soft skills,
                  fostering a friendly, global environment. The intense study
                  sessions left me well-prepared and confident in my SDET
                  skills.
                </p>
                <p style={{ fontWeight: "bold" }}>Alex Turner</p>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faComment}
                  size="2xl"
                  beat
                  style={{ color: "#960e0e", marginBottom: "20px" }}
                />

                <p>
                  Learn-IT's ReactJS boot camp was fantastic! The course
                  immersed me in ReactJS development, enhancing both technical
                  and soft skills. Connecting with classmates globally created a
                  diverse and enjoyable learning community, and the dynamic
                  learning approach made my time rewarding.
                </p>
                <p style={{ fontWeight: "bold" }}>Maya Patel</p>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faComment}
                  size="2xl"
                  beat
                  style={{ color: "#960e0e", marginBottom: "20px" }}
                />

                <p>
                  Discovering Java with Learn-IT was exceptional! The course
                  delved deep into programming, improving both technical and
                  soft skills. Building global connections with classmates
                  enriched my experience, and the fast-paced learning structure
                  kept me engaged.
                </p>
                <p ref={ref} style={{ fontWeight: "bold" }}>
                  {" "}
                  Carlos Ramirez
                </p>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faComment}
                  size="2xl"
                  beat
                  style={{ color: "#960e0e", marginBottom: "20px" }}
                />

                <p>
                  Learn-IT's DevOps boot camp was a game-changer! The program
                  elevated my technical and soft skills, fostering a global
                  community. The intense learning pace made my time immersive
                  and valuable.
                </p>
                <p style={{ fontWeight: "bold" }}>Aisha Khan</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section3">
          <div className="section3-left" style={{ fontFamily: "Varela Round" }}>
            <h1 style={{ fontWeight: "bold" }}>Have any queries ?</h1>
            <h5>Get a free counselling session from our experts</h5>
            <ul>
              <FontAwesomeIcon
                icon={faLocationDot}
                size="xl"
                style={{ color: "#1567f4" }}
              />
              <li style={{ fontWeight: "bold" }}>Locations</li>
              <li style={{ color: "grey" }}>USA</li>
              <p>3942, N California Ave 2 E Chicago Illinois 60618</p>
              <li style={{ color: "grey" }}>India</li>
              <p>
                05 Jeevan Basera Apparment, Annapurna Mandir, Indore, MP 452009
              </p>
              <FontAwesomeIcon
                icon={faPhone}
                size="xl"
                style={{ color: "#1461e6" }}
              />
              <li style={{ fontWeight: "bold" }}>Phone </li>
              <p style={{ color: "grey" }}>
                US &nbsp;
                <span style={{ color: "black" }}>+1-872-806-5906</span>
              </p>
              <p style={{ color: "grey" }}>
                IND&nbsp;
                <span style={{ color: "black" }}>+91-982-696-4641</span>
              </p>

              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ color: "#165fd4" }}
                size="xl"
              />
              <li style={{ fontWeight: "bold" }}>E-mail</li>
              <p>info@techryders.com</p>
            </ul>
          </div>
          <div className="section3-right">
            <div className="form-card">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    placeholder="Name*"
                    autoComplete="nope"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder="Email*"
                    autoComplete="nope"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="contactNumber"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    required
                    placeholder="Contact Number*"
                    autoComplete="nope"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    placeholder="Message*"
                    autoComplete="nope"
                    style={{ resize: "none" }}
                  />
                </div>
                {/* Display form errors */}
                <div className="form-errors">
                  <p style={{ color: "red" }}>{formErrors.name}</p>
                  <p style={{ color: "red" }}>{formErrors.email}</p>
                  <p style={{ color: "red" }}>{formErrors.contactNumber}</p>
                  <p style={{ color: "red" }}>{formErrors.message}</p>
                </div>

                {/* Display submission success message */}
                {formSubmitted && (
                  <p style={{ color: "Green" }}>Form submitted successfully!</p>
                )}
                <button className="button" type="submit">
                  Apply
                </button>
              </form>
            </div>
          </div>
        </div>
        <ScrollButton></ScrollButton>
      </div>
      <footer className="Learnfooter">
        <div className="Learnfooter-container">
          <div className="Learnlogo">
            <h3>Learn-IT</h3>
            <p>Copyright © 2023</p>
          </div>
          <div className="Learnlogo">
            <h3>Connect Us </h3>
            <p>
              <a
                href="https://www.instagram.com/learn-IT/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="2xl"
                  style={{ color: "#c41c3e" }}
                />
              </a>
              &nbsp;
              <a
                href="https://www.facebook.com/people/Learn-IT/61554957844566/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="2xl"
                  style={{ color: "white" }}
                />
              </a>
              &nbsp;
              <a
                href="https://www.twitter.com/learn-IT/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faXTwitter}
                  size="2xl"
                  style={{ color: "white" }}
                />
              </a>
            </p>
          </div>
          <div className="Learnlogo">
            <h3>Explore</h3>

            <li onClick={handleCourse}>Courses</li>
            <li onClick={handleEvents}>Upcoming Events</li>
            <li onClick={handleApply}>Contact Us</li>
          </div>
          <div className="Learnlogo">
            <h3>Contact Us</h3>
            <p>
              <ReactCountryFlag
                countryCode="US"
                svg
                className="flag-icon"
                style={{
                  fontSize: "30px",
                  borderRadius: "10px",
                  marginRight: "10px",
                }}
              />{" "}
              USA
            </p>
            <p>
              {" "}
              <FontAwesomeIcon
                icon={faLocationDot}
                size="xl"
                style={{ color: "#960e0e" }}
              />{" "}
              &nbsp; 3942, N California Ave 2 E Chicago Illinois 60618
            </p>
            <p>
              {" "}
              <FontAwesomeIcon
                icon={faPhone}
                size="xl"
                style={{ color: "#960e0e" }}
              />{" "}
              &nbsp; +1-872-806-5906
            </p>
            <p>
              <ReactCountryFlag
                countryCode="IN"
                svg
                className="flag-icon"
                style={{
                  fontSize: "30px",
                  borderRadius: "10px",
                  marginRight: "10px",
                }}
              />{" "}
              India
            </p>
            <p>
              <FontAwesomeIcon
                icon={faLocationDot}
                size="xl"
                style={{ color: "#960e0e" }}
              />{" "}
              &nbsp; 05 Jeevan Basera Apparment, Annapurna Mandir, Indore, MP
              452009
            </p>
            <p>
              {" "}
              <FontAwesomeIcon
                icon={faPhone}
                size="xl"
                style={{ color: "#960e0e" }}
              />{" "}
              &nbsp; +91-982-696-4641
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Home;
