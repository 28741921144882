import React, { useEffect, useState } from "react";
import Layout from "../Components/LayOut";
import "../Css/Openings.css";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import HashTag from "../Components/HashTag";
import ScrollButton from "../Components/ScrollButton";

const ReactOpen = ({ setProgress }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [cv, setCV] = useState(null);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [cvError, setCVError] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  const validateForm = () => {
    let isValid = true;

    if (name.trim() === "") {
      setNameError("Please enter your name");
      isValid = false;
    } else {
      setNameError("");
    }

    if (email.trim() === "") {
      setEmailError("Please enter your email");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (mobile.trim() === "") {
      setMobileError("Please enter your mobile number");
      isValid = false;
    } else if (!/^\d{10}$/.test(mobile)) {
      setMobileError("Please enter a valid 10-digit mobile number");
      isValid = false;
    } else {
      setMobileError("");
    }

    if (cv === null) {
      setCVError("Please upload your CV");
      isValid = false;
    } else {
      setCVError("");
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("mobile", mobile);
        formData.append("linkedin", linkedin);
        formData.append("cv", cv);

        const response = await fetch("https://techryders.com/career", {
          method: "POST",
          body: formData,
        });

        if (response.status === 200) {
          // Handle success on the frontend
          setName("");
          setEmail("");
          setMobile("");
          setLinkedin("");
          setCV(null);
          setFormSubmitted(true);
        }
      } catch (error) {
        console.error("Error submitting the career application:", error);
      }
    }
  };

  const handleApplyNow = () => {
    setShowCard(true);
    setFormSubmitted(false);
  };
  const handleCancel = () => {
    setShowCard(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  return (
    <Layout>
      <div className="background-Theme ">
        <div
          className="JavaOpen-container"
          style={{ fontFamily: "Varela Round" }}
        >
          <h1>We're Hiring</h1>
          <h5>React Developer</h5>
        </div>
      </div>
      <div className="Content">
        <h2 style={{ fontFamily: "Varela Round" }}>
          Position: Senior React.js Developer
        </h2>
        <h3 style={{ fontFamily: "Varela Round" }}>Experience: 3+ Years</h3>
        <h4 style={{ fontFamily: "Varela Round" }}>The role involves:</h4>
        <ul style={{ fontFamily: "sans-serif" }}>
          <li>
            Understanding business requirements and architecting/designing
            React.js-based solutions
          </li>
          <li>
            Building secure, scalable, high-performing, and testable React.js
            applications
          </li>
          <li>
            Taking ownership of project delivery while collaborating with team
            members
          </li>
        </ul>
        <h4 style={{ fontFamily: "Varela Round" }}>Required Skills:</h4>
        <ul style={{ fontFamily: "sans-serif" }}>
          <li>
            Passion for building and delivering high-quality React.js
            applications with a strong sense of ownership
          </li>
          <li>
            Defining all aspects of React.js development, including technology
            choices, workflows, and coding standards
          </li>
          <li>
            Ensuring React.js applications meet quality, security, and
            extensibility requirements
          </li>
          <li>
            Proficiency in React.js and modern JavaScript libraries and
            frameworks
          </li>
          <li>
            Experience in building user interfaces with React.js, Redux, or
            similar state management libraries
          </li>
          <li>
            Knowledge of front-end build tools, such as Webpack, Babel, or
            ESLint
          </li>
          <li>
            Experience with integrating and consuming RESTful APIs in React.js
            applications
          </li>
          <li>Familiarity with HTML, CSS, and responsive web design</li>
          <li>Experience with version control systems, such as Git</li>
          <li>Strong communication and mentorship skills</li>
          <li>Accurate estimation of project timelines</li>
          <li>Experience working in collaborative development teams</li>
        </ul>

        <h4 style={{ fontFamily: "Varela Round" }}>Functional Competencies:</h4>
        <ul style={{ fontFamily: "sans-serif" }}>
          <li>Strong problem-solving skills in React.js development</li>
          <li>Excellent design, coding, and refactoring skills in React.js</li>
          <li>Effective communication and presentation skills</li>
          <li>
            Awareness of the latest trends and best practices in front-end
            development, especially in the React.js ecosystem
          </li>
          <li>
            Passion for React.js technologies, products, and web development
            domains
          </li>
          <li>
            Ability to convey complex technical concepts in a clear and simple
            manner
          </li>
          <li>
            Innovative thinking to provide solutions to complex front-end
            problems
          </li>
          <li>
            Ability to multitask and contribute to multiple React.js projects
            concurrently
          </li>
          <li>
            Experience in building and maintaining large and complex front-end
            applications
          </li>
          <li>
            Familiarity with SaaS product development and cloud technology
            concepts
          </li>
          <li>
            Understanding of continuous integration and deployment practices
            (CI/CD) in the React.js ecosystem
          </li>
          <li>Readiness to learn and adapt to new React.js technologies</li>
        </ul>
        <button onClick={handleApplyNow} className="custom-openings-button-1">
          Apply Now
        </button>
      </div>
      {showCard && (
        <div style={{ height: "500px", marginTop: "100px" }}>
          {formSubmitted ? (
            <div className="text-center">
              <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
                Thank you for applying!
              </h1>
              <p>
                Your job application has been submitted successfully. We'll
                review your information and get in touch with you soon.
              </p>
            </div>
          ) : (
            <Card
              className="horizontal-card"
              style={{ borderRadius: "40px", fontFamily: "Varela Round" }}
            >
              <CardBody>
                <div className="Heading">Apply For Job</div>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input
                        autoComplete="nope"
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        invalid={nameError !== ""}
                      />
                      <FormFeedback>{nameError}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="email">Email Address</Label>
                      <Input
                        autoComplete="nope"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        invalid={emailError !== ""}
                      />
                      <FormFeedback>{emailError}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="mobile">Mobile Number</Label>
                      <Input
                        autoComplete="nope"
                        type="text"
                        name="mobile"
                        id="mobile"
                        placeholder="Your mobile number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        invalid={mobileError !== ""}
                      />
                      <FormFeedback>{mobileError}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="linkedin">LinkedIn Profile</Label>
                      <Input
                        autoComplete="nope"
                        type="text"
                        name="linkedin"
                        id="linkedin"
                        placeholder="Your LinkedIn profile URL"
                        value={linkedin}
                        onChange={(e) => setLinkedin(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="cv">Upload your CV</Label>
                      <Input
                        autoComplete="nope"
                        type="file"
                        name="cv"
                        id="cv"
                        onChange={(e) => setCV(e.target.files[0])}
                        invalid={cvError !== ""}
                      />
                      <FormFeedback>{cvError}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  style={{ borderRadius: "40px" }}
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                  <ToastContainer />
                </Button>{" "}
                <Button
                  style={{ borderRadius: "40px" }}
                  color="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </CardBody>
            </Card>
          )}
        </div>
      )}

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default ReactOpen;
