import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../Css/Etl.css";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import image1 from "../assets/Images/ETL1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import AOS from "aos";
import { Helmet } from "react-helmet";
import ScrollButton from "../Components/ScrollButton";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const Etl = ({ setProgress }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Cloud Data Quality Framework</title>
        <meta name="description" content="Get ETL tool   " />
      </Helmet>
      <div className="Etl-background" style={{ fontFamily: "Varela Round" }}>
        <div className="image-container-Etl">
          <img src={image1} alt="Image" />
        </div>
        <div className="content-Etl">
          <h2 style={{ fontWeight: "bolder", fontSize: "30px" }}>
            Cloud Data Quality Framework
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            Supercharge Your Data Quality with our Cloud-Powered Framework:
            Achieve High-Quality Data, 80% Faster!
          </p>
        </div>
      </div>

      <div className="etl-background2" style={{ fontFamily: "Varela Round" }}>
        <div className="image-container-etl2">
          <img src={image1} alt="Image" />
        </div>
        <div className="content-etl2">
          <h2 style={{ fontWeight: "bolder", fontSize: "25px" }}>
            Why Cloud Data Quality Framework ?{" "}
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "16px" }}>
            The quality of the data may be impacted by anomalies, various data
            sources yielding duplicate sets of data, difficulties with
            consistency, or both. Every area of your company that depends on
            data to guide its operations and decision-making must eliminate
            these problems and attain high data quality. Implementing a data
            quality framework is one of the key components to attaining this.
          </p>
          <button
            onClick={() => navigate("/contact")}
            className="custom-Etl-button-2"
          >
            Request For Demo <FaArrowRight />
          </button>
        </div>
      </div>

      <div>
        <div style={{ textAlign: "center", fontFamily: "Varela Round" }}>
          <h1 style={{ fontWeight: "bold" }}>
            Data Quality Framework features
          </h1>
        </div>
      </div>

      <div className="Timeline">
        <p className="Timeline-content" data-aos="fade-down">
          <h4>Cloud Compatibility</h4>
          The framework is capable of easily running workloads and test suites
          within any cloud environment, making it adaptable to various cloud
          providers.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>Cloud-Based Testing</h4>
          It can spin up testing from a local environment to trigger test
          execution over the cloud, allowing for efficient and scalable testing
          capabilities.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>Time Efficiency</h4>
          The framework saves 80% of time compared to manual data quality
          testing, and it is equipped to analyze large datasets with millions of
          rows and columns quickly.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>Support for Multiple File Formats</h4>
          It supports multiple file formats, including AVRO, CSV, JSON, ORC, and
          Parquet, ensuring compatibility with diverse data sources.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>Pre-defined Generic Data Quality Rules</h4>
          The framework includes pre-defined generic data quality rules such as
          Null Check, Duplicate Check, and Datasets Comparison, making it easier
          to identify and rectify common data quality issues.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>Custom Data Quality Rules</h4>
          Users can write and run custom data quality rules using SQL queries,
          allowing for flexibility and adaptability in addressing specific data
          quality concerns.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>Concurrent Execution and Reporting</h4>
          The framework supports concurrent execution of data quality rules,
          allows comparisons between different sources and aggregated datasets
          via SQL queries, and provides automated reporting and result updates
          in an RDS database for in-depth analysis.
        </p>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default Etl;
