import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../ServiceCss/ProductDevelopment.css";
import service from "../assets/Images/Service 5.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DoubleQuote1 from "../assets/Images/Double-quote-1.svg";
import DoubleQuote2 from "../assets/Images/Double-quote-2.svg";
import { Helmet } from "react-helmet";
import HorizontalMarquee from "../Components/HorizontalMarquee";
import ScrollButton from "../Components/ScrollButton";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const ProductDevelopment = ({ setProgress }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Product Development </title>
        <meta name="description" content="Product Development Services" />
      </Helmet>
      <div
        className="Product-background"
        style={{ fontFamily: "Varela Round" }}
      >
        <div className="image-container-Product">
          <img src={service} alt="Image" />
        </div>
        <div className="content-Product">
          <h2
            style={{
              fontWeight: "bolder",
              fontFamily: "Varela Round",
              fontSize: "30px",
            }}
          >
            Product Development
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            Bringing your visions to life: where your ideas meet our expertise,
            unleashing infinite possibilities in software product development.
          </p>
          <button onClick={() => navigate("/contact")} className="about-button">
            Let's Connect <FaArrowRight />
          </button>
        </div>
      </div>

      <div className="Cloud-Cards">
        <div className="left-1">
          <div className="left-2">
            <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
              Turn Your Digital Dreams into Reality with Our Product Development
              Solutions.
            </h1>
            <br></br>

            <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>
              {" "}
              Our product development services are meticulously designed to
              bring your unique ideas to life, taking your business to new
              heights. Whether you're envisioning a cutting-edge software
              solution or a powerful digital product, we're here to make it a
              reality.
              <br></br>
              <br></br>
              With a team of experts and a proven track record in the industry,
              we ensure a seamless and secure product development process. From
              conceptualization to deployment, we work diligently to bring your
              vision to market, with a focus on efficiency, quality, and
              innovation. Elevate your software portfolio and turn your vision
              into a successful reality with our product development solutions.
            </p>
          </div>
        </div>
        <div className="right-1">
          <div className="right-2">
            <img src={DoubleQuote1} />
            <br></br>
            <br></br>
            <p style={{ fontFamily: "sans-serif" }}>
              {" "}
              Techryders' product development exceeded our expectations. We're
              excited for future projects
            </p>
            <br></br>
            <h4>- Ben</h4>
            <br></br>
            <img src={DoubleQuote2} />
          </div>
        </div>
      </div>

      <div className="cloud-background1" style={{ fontFamily: "Varela Round" }}>
        <div>
          <p className="Cloud-Quote">
            "A product is not just a collection of features, it's a solution to
            a problem."
          </p>
        </div>
      </div>

      <section style={{ marginTop: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <h2 className="text-dark" style={{ fontFamily: "Varela Round" }}>
                Our{" "}
              </h2>
              <h1 style={{ color: "#fd761b", fontFamily: "Varela Round" }}>
                Offerings <FaArrowRight />
              </h1>

              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                  <p style={{ fontFamily: "sans-serif" }}>
                    We offer Product development services to assist our
                    customers build their businesses and stand out from the
                    competition market.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4 ",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>New Product Development</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Translate abstract ideas into cutting-edge products with
                      the power of product thinking and an innovation mindset.
                      Quickly develop a working prototype or MVP having
                      essential features and test it with your target customers.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3"></div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                  <div
                    className="p-3 mb-3"
                    style={{
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Agile DevOps services</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      As part of our core product engineering services, we
                      provide the following DevOps implementation services -
                      DevOps strategy & consulting, Enterprise DevOps
                      implementation and automate core DevOps processes across
                      the development to delivery pipeline.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Continuous Product Innovation</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Continuous innovation encompasses a comprehensive set of
                      organizational capabilities such as operational
                      excellence, technology infrastructure to support
                      continuous delivery, and an innovation mindset that’s
                      perfectly in tune with evolving market demands.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Product Implementation</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      As part of our full lifecycle support, our CoE team helps
                      you with integrations, customizations, enhancements and
                      deployment. We walk the extra mile to ensure that they are
                      technically accurate and well-tested prior to going live.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Quality Engineering</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      We integrate Quality Engineering into every aspect of the
                      product development lifecycle and do not pursue a separate
                      testing phase. Giving you an early edge in making the
                      product market ready before competitors.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Agile Development</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Agile development is no longer just a luxury but rather an
                      imminent need. Our Agile Development model includes Agile
                      strategy & consulting, Enterprise agile strategy &
                      implementation, and Agile coaching and mentoring.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Support and Maintenance</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Revitalize your Product with our expert support and
                      maintenance. We're here to fine-tune, nurture, and
                      optimize your existing Product, ensuring it thrives and
                      evolves seamlessly. Stay ahead with our unwavering
                      support.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Service-Page-Work" data-background-title="Process">
        <div class="container container--column">
          <h1 class="section__title-wrap">
            <span class="section__subtitle">How do we work?</span>
          </h1>
          <div class="development-timeline">
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  {" "}
                  Conceptualization
                </span>
                <p class="development-timeline__description">
                  Teams brainstorm, refine concepts, and define product purpose,
                  target audience, and key features.
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Design and Prototyping
                </span>
                <p class="development-timeline__description">
                  Designers create specs and prototypes, seeking user feedback
                  for UI/UX improvement.
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Development and Engineering
                </span>
                <p class="development-timeline__description">
                  Transition from design to product, involving sourcing,
                  manufacturing, coding, testing, and refining by
                  cross-functional teams.{" "}
                </p>
              </div>
            </div>
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Testing and Quality Assurance
                </span>
                <p class="development-timeline__description">
                  Rigorous testing, including functional, performance, and
                  security, ensures a defect-free product. User feedback guides
                  improvements.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Launch and Post-Launch Activities
                </span>
                <p class="development-timeline__description">
                  After thorough testing, launch the product with marketing and
                  support. Continuous user feedback drives updates and
                  enhancements to stay agile and meet evolving needs.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="background-cloud6" style={{ fontFamily: "Varela Round" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Technologies
        </h1>
        <p
          style={{
            textAlign: "center",
            alignItems: "center",
            fontSize: "20px",
            padding: "20px 50px 0",
            fontFamily: "sans-serif",
          }}
        >
          Our experts offers integrated services and our awesome product help
          you harness the power of technology. Streamline and automate IT.
          Enhance customer experiences, and drive exponential business growth.
        </p>

        {/* <MarqueeCarousel images={imageUrls} imageSize={imageSize} speed={speed} /> */}

        <div>
          <HorizontalMarquee />
        </div>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default ProductDevelopment;
