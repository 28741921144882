import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../Css/Aboutus.css";
import image1 from "../assets/Images/About1.png";
import image2 from "../assets/Images/About2.png";
import image3 from "../assets/Images/About1.svg";
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import amazon from "../assets/BackgroundImages/amazon.png";
import azure from "../assets/BackgroundImages/azure.png";
import googleai from "../assets/BackgroundImages/googleAi.png";
import googlecloud from "../assets/BackgroundImages/googlecloud.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import VerticalSlider from "../Components/VerticalSlider";
import qualityImage from "../assets/Values/value1.png";
import serviceImage from "../assets/Values/value2.png";
import satisfactionImage from "../assets/Values/value3.png";
import timelinesImage from "../assets/Values/value4.png";
import expertiseImage from "../assets/Values/value5.png";
import ScrollButton from "../Components/ScrollButton";
import fintech from "../assets/Industries/Fintech.svg";
import Travel from "../assets/Industries/Travel.svg";
import EDTech from "../assets/Industries/EDTech.svg";
import Climate from "../assets/Industries/ClimateTech.svg";
import Agri from "../assets/Industries/Agriculture.svg";
import health from "../assets/Industries/healthcare.svg";
import PublicSector from "../assets/Industries/PublicSector.svg";
import Proptech from "../assets/Industries/Proptech.svg";
import { Helmet } from "react-helmet";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const AboutUs = ({ setProgress }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="Explore About Us" />
      </Helmet>
      <div className="about-background">
        <div className="image-about">
          <img src={image3} alt="Image" />
        </div>
        <div className="about-1">
          <h2
            style={{
              fontWeight: "bolder",
              fontFamily: "Varela Round",
              fontSize: "30px",
            }}
          >
            Who We Are
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            We are a forward-thinking IT company dedicated to pushing the
            boundaries of technology and creating innovative solutions that
            empower businesses. At Techryders, we believe in the transformative
            power of technology and its ability to shape a better future.
          </p>
        </div>
      </div>

      <div className="content-container-about">
        <div className="image-container-about">
          <img src={image1} alt="Image" />
        </div>
        <div className="text-container-about">
          <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
            Focused on Actionable Insights
          </h1>
          <p style={{ fontSize: "16px", fontFamily: "sans-serif" }}>
            We provide comprehensive support for software development projects,
            including idea incubation, transitioning from existing solutions,
            code reviews, and bug fixes. Our team collaborates closely with
            clients to plan and ideate new software, aligning it with business
            objectives and incorporating the latest technologies. We also guide
            clients through the complex process of transitioning from existing
            solutions, minimizing disruptions and designing tailored approaches.
            With our expertise, we conduct thorough code reviews, optimize code
            performance, enhance security, and ensure compliance with industry
            standards. As believers in the power of innovation, we strive to
            drive evolution in the software industry, delivering cutting-edge
            solutions that propel organizations forward. Whether you need
            guidance, development, transition support, or code optimization, we
            are dedicated to your success in the ever-evolving digital
            landscape.
          </p>
          <ul style={{ fontSize: "16px", fontFamily: "sans-serif" }}>
            <li>Professional Consultancy Service</li>
            <li>24/7 Support Center</li>
            <li>Custom Service & Operation</li>
            <li>Advanced Advisory Team</li>
          </ul>
        </div>
      </div>

      <div className="background-about-8">
        <Container className="text-center">
          <div>
            <h1
              className="main-head  "
              style={{
                fontFamily: "Varela Round",
                fontWeight: "bold",
                paddingTop: "30px",
              }}
            >
              Our Values
            </h1>
            <p
              className="title-head centered ptag"
              style={{ fontFamily: "sans-serif" }}
            >
              <h5>Our core purpose is to serve our customers well</h5>
            </p>
          </div>
          <Row
            className="mainabout justify-content-center"
            style={{ fontFamily: "sans-serif", marginTop: "50px" }}
          >
            <Col md="2" data-aos="fade-down">
              <div className="aboutblock">
                <div className="process_item">
                  <div className="mainimg">
                    <img src={qualityImage} alt="Quality Focus" />
                  </div>
                  <h5
                    data-text="01"
                    style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                  >
                    Quality Focus
                  </h5>
                </div>
              </div>
            </Col>
            <Col md="2" data-aos="fade-down">
              <div className="aboutblock">
                <div className="process_item">
                  <div className="mainimg">
                    <img src={serviceImage} alt="Superior Service" />
                  </div>
                  <h5
                    data-text="02"
                    style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                  >
                    Superior Service
                  </h5>
                </div>
              </div>
            </Col>
            <Col md="2" data-aos="fade-down">
              <div className="aboutblock">
                <div className="process_item">
                  <div className="mainimg">
                    <img src={satisfactionImage} alt="User Satisfaction" />
                  </div>
                  <h5
                    data-text="03"
                    style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                  >
                    Client Satisfaction
                  </h5>
                </div>
              </div>
            </Col>
            <Col md="2" data-aos="fade-down">
              <div className="aboutblock">
                <div className="process_item">
                  <div className="mainimg">
                    <img src={timelinesImage} alt="Timelines" />
                  </div>
                  <h5
                    data-text="04"
                    style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                  >
                    Timelines
                  </h5>
                </div>
              </div>
            </Col>
            {/* Add the Expertise column */}
            <Col md="2" data-aos="fade-down">
              <div className="aboutblock">
                <div className="process_item">
                  <div className="mainimg">
                    <img src={expertiseImage} alt="Expertise" />
                  </div>
                  <h5
                    data-text="05"
                    style={{ marginTop: "20px", fontFamily: "sans-serif" }}
                  >
                    Expertise
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section
        class="container-fluid py-5 innovat-section aos-init aos-animate"
        data-aos="fade-up"
        style={{ marginTop: "100px" }}
      >
        <div class="container">
          <div class="row">
            <h1
              class=" text-center "
              style={{ fontFamily: "Varela Round", fontWeight: "bold" }}
            >
              Industries That Fuel Our Passion.
            </h1>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="passion-box">
                <img src={fintech} alt="Fintech" />
                <p style={{ fontFamily: "sans-serif" }}>Fintech</p>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="passion-box">
                <img src={Travel} alt="Travel & Hospitality" />
                <p style={{ fontFamily: "sans-serif" }}>Travel & Hospitality</p>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="passion-box">
                <img src={EDTech} />
                <p style={{ fontFamily: "sans-serif" }}>Edtech</p>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="passion-box">
                <img src={Climate} alt="Climate Tech" />
                <p style={{ fontFamily: "sans-serif" }}>Climate Tech</p>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="passion-box">
                <img src={Agri} alt="Fantasy Sports" />
                <p style={{ fontFamily: "sans-serif" }}>Agritech</p>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="passion-box">
                <img src={health} alt="Agritech" />
                <p style={{ fontFamily: "sans-serif" }}>Health Care</p>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="passion-box">
                <img src={PublicSector} alt="Public Sector" />
                <p style={{ fontFamily: "sans-serif" }}>Public Sector</p>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="passion-box">
                <img src={Proptech} alt="Proptech" />
                <p style={{ fontFamily: "sans-serif" }}>Proptech</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="background-about-1">
        <div className="flex-container-about">
          <h1
            style={{
              fontFamily: "Varela Round",
              fontWeight: "bold",
              color: "white",
            }}
          >
            Excellence, Reliability, Transparency &amp; Innovation
          </h1>
          <p
            style={{
              fontFamily: "sans-serif",
              fontSize: "18px",
              color: "white",
            }}
          >
            Techryders, with its strong Vision, Mission, and Values, builds
            technologically driven, scalable, and cutting-edge solutions to
            deliver profitability in your business. We ensure you receive a
            perfect solution at a nominal cost.
          </p>
          <div class="box-container">
            <div class="box" data-aos="fade-down">
              <div className="box-text" style={{ fontFamily: "sans-serif" }}>
                50+
                <div className="box-text" style={{ fontFamily: "sans-serif" }}>
                  Projects and Software Developed
                </div>
              </div>
            </div>
            <div class="box" data-aos="fade-down">
              <div className="box-text" style={{ fontFamily: "sans-serif" }}>
                40+
                <div className="box-text" style={{ fontFamily: "sans-serif" }}>
                  Qualified Employees and Developers
                </div>
              </div>
            </div>
            <div class="box" data-aos="fade-down">
              <div className="box-text" style={{ fontFamily: "sans-serif" }}>
                100+
                <div className="box-text" style={{ fontFamily: "sans-serif" }}>
                  WorldWide Customers Network
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-container-about-1">
          <img src={image2} alt="Image" className="image-about-2" />
        </div>
      </div>

      <div className="background-about-3">
        <div className="certificate-container">
          <div>
            <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
              OUR CERTIFICATION
            </h1>
            <h4 style={{ fontFamily: "Varela Round" }}>
              High Achievers At Your Service
            </h4>
          </div>
        </div>
        <div class="box-container-2">
          <div class="box-2" data-aos="fade-down">
            <img className="box-img1" src={amazon} />
            <hr></hr>
            <p style={{ fontFamily: "sans-serif" }}>Amazon web Services</p>
          </div>
          <div class="box-2" data-aos="fade-down">
            <img className="box-img2" src={azure} />
            <hr></hr>
            <p style={{ fontFamily: "sans-serif" }}>Microsoft Azure Services</p>
          </div>
          <div class="box-2" data-aos="fade-down">
            <img className="box-img3" src={googleai} />
            <hr></hr>
            <p style={{ fontFamily: "sans-serif" }}>
              Google Artificial Intelligence
            </p>
          </div>
          <div class="box-2" data-aos="fade-down">
            <img className="box-img4" src={googlecloud} />
            <hr></hr>
            <p style={{ fontFamily: "sans-serif" }}>Google Cloud Services</p>
          </div>
        </div>
      </div>

      <div className="background-about-6">
        <div className="flex-about">
          <h1 style={{ fontWeight: "bold", fontFamily: "Varela Round" }}>
            Client Testimonial
          </h1>
          <p style={{ fontFamily: "sans-serif", fontSize: "16px" }}>
            Our Clients send us bunch of smiles with our services and we love
            them
          </p>
          <button onClick={() => navigate("/contact")} className="about-button">
            Let's Connect <FaArrowRight />
          </button>
        </div>
        <div className="flex-about-2">
          <VerticalSlider />
        </div>
      </div>

      {/* <div className='background-about-7'>
       <div className='ourclients'>

       <h1 style={{ fontWeight:'bold', fontFamily: 'Varela Round' }}>Our Clients</h1>
        <p style={{  fontFamily: 'Varela Round'}}>Our experts offers integrated services and our awesome product help you harness the power of technology. streamline and automate IT. enhance customer experiences, and drive exponential business growth.</p>
     
       </div>
      

        <div >
        <Marquee direction="left" speed={100} delay={5} style={{ marginTop :'100px'}}>
          <div className="image_wrapper">
            <img src={Marq1} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={Marq2} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={Marq3} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={Marq4} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={Marq5} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={Marq6} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={Marq7} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={Marq8} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={Marq9} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={Marq10} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={Marq11} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={Marq12} alt="" />
          </div>
          
        </Marquee>
      </div>
      </div> */}

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default AboutUs;
