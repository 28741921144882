import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { BsTelephoneFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import logo2 from "../assets/Images/Techryders-2.png";
import "../Css/Navbar.css";

const NavBar = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarBackground = scrolled ? "black" : "transparent";

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div>
      <Navbar
        expand="lg"
        fixed="top"
        className="navbar"
        style={{ background: navbarBackground, transition: "background 0.05s" }}
      >
        <NavbarBrand
          tag={Link}
          to="/"
          onClick={() => {
            {
              window.scrollTo(0, 0);
            }
          }}
        >
          <img src={logo2} alt="Logo" className="logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink
                tag={Link}
                to="/about"
                style={{
                  color: "White",
                  marginRight: "30px",
                  fontFamily: "Varela Round",
                }}
                className={
                  location.pathname === "/about"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => {
                  {
                    window.scrollTo(0, 0);
                  }
                }}
              >
                About Us
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar className="services-dropdown">
              <NavLink
                tag={Link}
                to="/services"
                style={{
                  color: "White",
                  marginRight: "30px",
                  fontFamily: "Varela Round",
                }}
                className={
                  location.pathname === "/services"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => {
                  {
                    window.scrollTo(0, 0);
                  }
                }}
              >
                Services <FaAngleDown />
              </NavLink>
              <DropdownMenu
                right
                className="services-content"
                style={{ fontFamily: "Varela Round" }}
              >
                <DropdownItem
                  tag={Link}
                  to="/services/cloud"
                  onClick={stopPropagation}
                >
                  Cloud
                </DropdownItem>
                <DropdownItem
                  tag={Link}
                  to="/services/data-analytics"
                  onClick={stopPropagation}
                >
                  Data and Analytics
                </DropdownItem>
                <DropdownItem
                  tag={Link}
                  to="/services/enterprise-solutions"
                  onClick={stopPropagation}
                >
                  Enterprise Solutions
                </DropdownItem>
                <DropdownItem
                  tag={Link}
                  to="/services/mobile-app-development"
                  onClick={stopPropagation}
                >
                  Mobile Development
                </DropdownItem>
                <DropdownItem
                  tag={Link}
                  to="/services/ProductDevelopment"
                  onClick={stopPropagation}
                >
                  Product Development
                </DropdownItem>
                <DropdownItem
                  tag={Link}
                  to="/services/resource-augmentation"
                  onClick={stopPropagation}
                >
                  Resource Augmentation
                </DropdownItem>
                <DropdownItem
                  tag={Link}
                  to="/services/qa-testing"
                  onClick={stopPropagation}
                >
                  Testing/QA
                </DropdownItem>
                <DropdownItem
                  tag={Link}
                  to="/services/ux-ui-design"
                  onClick={stopPropagation}
                >
                  UX/UI Design
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink
                tag={Link}
                to="/careers"
                style={{
                  color: "White",
                  marginRight: "30px",
                  fontFamily: "Varela Round",
                }}
                className={
                  location.pathname === "/careers"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => {
                  {
                    window.scrollTo(0, 0);
                  }
                }}
              >
                Careers
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/ourproduct"
                style={{
                  color: "White",
                  marginRight: "30px",
                  fontFamily: "Varela Round",
                }}
                className={
                  location.pathname === "/ourproduct"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => {
                  {
                    window.scrollTo(0, 0);
                  }
                }}
              >
                Our Product
              </NavLink>
            </NavItem>

            {/* <NavItem>
              <NavLink
                tag={Link}
                to="/blog"
                style={{
                  color: "White",
                  marginRight: "50px",
                  fontFamily: "Varela Round",
                }}
                className={
                  location.pathname === "/blog" ? "nav-link active" : "nav-link"
                }
                onClick={() => {
                  {
                    window.scrollTo(0, 0);
                  }
                }}
              >
                Blog
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                tag={Link}
                to="/Learn-IT"
                style={{
                  color: "White",
                  marginRight: "30px",
                  fontFamily: "Varela Round",
                }}
                className={
                  location.pathname === "/Learn-IT"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => {
                  {
                    window.scrollTo(0, 0);
                  }
                }}
              >
                Learn-IT
              </NavLink>
            </NavItem>
          </Nav>
          <button
            className="contact-button"
            onClick={() => {
              if (window.location.pathname === "/contact") {
                window.scrollTo(0, 0);
                window.location.reload();
              } else {
                navigate("/contact");
              }
            }}
            style={{ fontFamily: "Varela Round" }}
          >
            <BsTelephoneFill className="telephone-icon" /> Contact Us
          </button>
          
          {/* <button
            className="Learn-button"
            onClick={() => {
              if (window.location.pathname === "/Learn_IT") {
                window.scrollTo(0, 0);
              } else {
                window.scrollTo(0, 0);
                navigate("/Learn-IT");
              }
            }}
            style={{ fontFamily: "Varela Round" }}
          >
            Learn-IT
          </button> */}
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
