import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../Css/Erp.css";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import image1 from "../assets/Images/ERP2.png";
import image2 from "../assets/Images/ERP1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import ScrollButton from "../Components/ScrollButton";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const Erp = ({ setProgress }) => {
  useEffect(() => {
    AOS.init({
      duration: 800, // Animation duration in milliseconds
    });
  }, []);
  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Inventocart</title>
        <meta name="description" content="Get ERP tool  " />
      </Helmet>

      <div className="Erp-background" style={{ fontFamily: "Varela Round" }}>
        <div className="image-container-Erp">
          <img src={image1} alt="Image" />
        </div>
        <div className="content-Erp">
          <h2 style={{ fontWeight: "bolder", fontSize: "30px" }}>
            Inventocart
          </h2>
          <p style={{ fontFamily: "Varela Round", fontSize: "18px" }}>
            Streamline, Optimize, and Thrive with Inventocart: Unleash Your
            Business Potential with Our Digital Solution.
          </p>
          {/* <button onClick={() => navigate("/contact")} className="custom-Erp-button-1" >
              Let's Connect<FaArrowRight />
            </button> */}
        </div>
      </div>

      <div className="erp-background2" style={{ fontFamily: "Varela Round" }}>
        <div className="image-container-erp2">
          <img src={image2} alt="Image" />
        </div>
        <div className="content-erp2">
          <h2 style={{ fontWeight: "bolder", fontSize: "25px" }}>
            Why Inventocart ?
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "16px" }}>
            Inventocart provides a wide range of features for e-commerce,
            manufacturing, project management, retail, and trade. It offers a
            collection of business apps that combine and automate many of an
            enterprise's operational procedures. It is applicable to all sizes
            and sectors of enterprises worldwide. The product includes various
            modules and features.
          </p>

          <button
            onClick={() => navigate("/contact")}
            className="custom-Erp-button-2"
          >
            Request For Demo <FaArrowRight />
          </button>
        </div>
      </div>

      <div>
        <div style={{ textAlign: "center", fontFamily: "Varela Round" }}>
          <h1 style={{ fontWeight: "bold" }}> Inventocart Features </h1>
        </div>
      </div>

      <div className="Timeline">
        <p className="Timeline-content" data-aos="fade-down">
          <h4>Manufacturing</h4>
          Enables efficient manufacturing processes through Bill of Materials,
          job shop support, production planning, cost tracking, equipment
          billing, and detailed reporting.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>Inventory Management</h4>
          Efficiently handles inventory across multiple warehouses with
          serialized and lot management, seamless shipments, picklist
          management, and easy returns.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>Catalog Management</h4>
          Supports multiple stores, diverse product types, gift cards, price
          rules, promotions, and payment gateway integration for seamless online
          and POS sales.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>CRM & Order Management</h4>
          Covers lead and sales opportunity management, shared team
          collaboration, email integration, customer service, quotes, orders,
          and campaign tracking.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>E-commerce</h4>
          This system supports limitless online stores, various product types,
          and features for product promotion, gift cards, pricing rules, and
          payment gateway integration.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>HR</h4>
          Facilitates HR management with organizational structure, job position
          management, recruitment support, salary handling, employment
          contracts, expenses, and training tracking.{" "}
        </p>
        <p className="Timeline-content" data-aos="fade-down">
          <h4>Accounting</h4>
          It offers a robust financial platform with double-entry accounting,
          multi-organization support, and features for accounts, invoices,
          assets, budgets, payment processing, and integrated financial
          reporting.{" "}
        </p>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default Erp;
