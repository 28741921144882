import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../ServiceCss/EnterpriseSolution.css";
import service from "../assets/Images/Service 3.svg";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import DoubleQuote1 from "../assets/Images/Double-quote-1.svg";
import DoubleQuote2 from "../assets/Images/Double-quote-2.svg";
import { Helmet } from "react-helmet";
import HorizontalMarquee from "../Components/HorizontalMarquee";
import ScrollButton from "../Components/ScrollButton";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const EnterpriseSolution = ({ setProgress }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Enterprise Solution</title>
        <meta name="description" content="Enterpise Solution Services" />
      </Helmet>
      <div
        className="Enterprise-background"
        style={{ fontFamily: "Varela Round" }}
      >
        <div className="image-container-Enterprise">
          <img src={service} alt="Image" />
        </div>
        <div className="content-Enterprise">
          <h2
            style={{
              fontWeight: "bolder",
              fontFamily: "Varela Round",
              fontSize: "30px",
            }}
          >
            Enterprise Solution
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            Elevate your business with our cutting-edge software solutions.
            Streamline operations, drive efficiency, and achieve unprecedented
            growth.
          </p>
          <button onClick={() => navigate("/contact")} className="about-button">
            Let's Connect <FaArrowRight />
          </button>
        </div>
      </div>

      <div className="Cloud-Cards">
        <div className="left-1">
          <div className="left-2">
            <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
              Empowering Enterprises Through Seamless Solutions: Where Vision
              Meets Innovation.
            </h1>
            <br></br>

            <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>
              {" "}
              Our enterprise software development services are meticulously
              designed to fuel your organization's growth, efficiency, and
              innovation. We specialize in creating custom software solutions
              that address your unique business challenges and objectives.
              <br></br>
              <br></br>
              Our team of skilled developers and experts in enterprise
              architecture collaborate closely with you to understand your
              specific needs. From designing and coding to testing and
              deployment, we ensure that every aspect of your software aligns
              perfectly with your enterprise's goals. Elevate your enterprise to
              new heights with our innovative software development expertise.
            </p>
          </div>
        </div>
        <div className="right-1">
          <div className="right-2">
            <img src={DoubleQuote1} />
            <br></br>
            <br></br>
            <p style={{ fontFamily: "sans-serif" }}>
              Techryders' enterprise solutions have streamlined our operations
              and saved us time and resources. We look forward to a long
              partnership.
            </p>
            <br></br>
            <h4>- joe</h4>
            <br></br>
            <img src={DoubleQuote2} />
          </div>
        </div>
      </div>

      <div className="cloud-background1" style={{ fontFamily: "Varela Round" }}>
        <div>
          <p className="Cloud-Quote">
            "Enhancing processes and efficiency to drive transformative results
            in enterprise solutions."
          </p>
        </div>
      </div>

      <section style={{ marginTop: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <h2 className="text-dark" style={{ fontFamily: "Varela Round" }}>
                Our{" "}
              </h2>
              <h1 style={{ color: "#fd761b", fontFamily: "Varela Round" }}>
                Offerings <FaArrowRight />
              </h1>

              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                  <p>
                    We transform enterprises and modernize their IT landscape by
                    designing new-age solutions that reuse their existing IT
                    investments thereby increasing their digital ROI.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4 ",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Idea to MVP Transformation</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Turn your tech concept into reality and let your
                      enterprise flourish on the path of innovation faster with
                      our expert guidance.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3"></div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4 ",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Support And Maintenance </strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Unlock peace of mind with our premier support and
                      maintenance services for your Enterprise. Our dedicated
                      team is here to ensure your system runs smoothly, so you
                      can focus on what matters most – your business. Experience
                      unparalleled reliability and a partner you can trust.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Strategy and Consulting Services</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Harness strategized roadmaps to drive transformative
                      change within your enterprise, led by our consulting
                      experts.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>
                        Enterprise Architecture and Digital Platforms
                      </strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      Bring transformation within your enterprise by solving
                      challenges around digital platforms & conducting
                      architecture evaluations.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Solution Engineering</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      Achieve cost-effective and reliable solution engineering
                      services for your enterprise built using advanced
                      technologies and utilizing existing IT investments.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Enterprise Cognition Engineering</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Automate and re-engineer workflows & processes of your
                      enterprise with intelligent cognition engineering
                      practices{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Service-Page-Work" data-background-title="Process">
        <div class="container container--column">
          <h1 class="section__title-wrap">
            <span class="section__subtitle">How do we work?</span>
          </h1>
          <div class="development-timeline">
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Comprehensive Requirements Gathering
                </span>
                <p class="development-timeline__description">
                  Thoroughly analyze and document the unique needs and
                  challenges of the enterprise, ensuring a deep understanding of
                  business processes and objectives.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Scalable Architecture Design
                </span>
                <p class="development-timeline__description">
                  Create a flexible and scalable solution architecture that can
                  accommodate growth and changing requirements, allowing the
                  enterprise to adapt and expand without significant rework.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Rigorous Testing and Quality Assurance
                </span>
                <p class="development-timeline__description">
                  Implement rigorous testing procedures, including security,
                  performance, and compliance testing, to ensure the enterprise
                  solution meets quality standards and regulatory requirements.{" "}
                </p>
              </div>
            </div>
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Effective Change Management
                </span>
                <p class="development-timeline__description">
                  Develop a robust change management strategy to minimize
                  disruption during implementation, involving stakeholders and
                  providing training to ensure a smooth transition.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Continuous Monitoring and Improvement
                </span>
                <p class="development-timeline__description">
                  Implement ongoing monitoring and maintenance to proactively
                  identify and address issues, and regularly enhance the
                  enterprise solution to meet evolving needs and technological
                  advancements.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="background-cloud6" style={{ fontFamily: "Varela Round" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Technologies
        </h1>
        <p
          style={{
            textAlign: "center",
            alignItems: "center",
            fontSize: "20px",
            padding: "20px 50px 0",
            fontFamily: "sans-serif",
          }}
        >
          Our experts offers integrated services and our awesome product help
          you harness the power of technology. Streamline and automate IT.
          Enhance customer experiences, and drive exponential business growth.
        </p>

        <div>
          <HorizontalMarquee />
        </div>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default EnterpriseSolution;
