import React, { useEffect, useRef, useState } from "react";
import Layout from "../Components/LayOut";
import "../Css/Openings.css";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HashTag from "../Components/HashTag";
import ScrollButton from "../Components/ScrollButton";

const DotNetOpen = ({ setProgress }) => {
  const ref = useRef(null);
  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [cv, setCV] = useState(null);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [cvError, setCVError] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateForm = () => {
    let isValid = true;

    if (name.trim() === "") {
      setNameError("Please enter your name");
      isValid = false;
    } else {
      setNameError("");
    }

    if (email.trim() === "") {
      setEmailError("Please enter your email");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (mobile.trim() === "") {
      setMobileError("Please enter your mobile number");
      isValid = false;
    } else if (!/^\d{10}$/.test(mobile)) {
      setMobileError("Please enter a valid 10-digit mobile number");
      isValid = false;
    } else {
      setMobileError("");
    }

    if (cv === null) {
      setCVError("Please upload your CV");
      isValid = false;
    } else {
      setCVError("");
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("mobile", mobile);
        formData.append("linkedin", linkedin);
        formData.append("cv", cv);

        const response = await fetch("https://techryders.com/career", {
          method: "POST",
          body: formData,
        });

        if (response.status === 200) {
          // Handle success on the frontend
          setName("");
          setEmail("");
          setMobile("");
          setLinkedin("");
          setCV(null);
          setFormSubmitted(true);
          ref.current?.scrollIntoView({ behavior: "smooth" });
        }
      } catch (error) {
        console.error("Error submitting the career application:", error);
      }
    }
  };

  const handleApplyNow = () => {
    setShowCard(true);
    setFormSubmitted(false);
  };

  const handleCancel = () => {
    setShowCard(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  return (
    <Layout>
      <div className="background-Theme ">
        <div
          className="JavaOpen-container"
          style={{ fontFamily: "Varela Round" }}
        >
          <h1>We're Hiring</h1>
          <h5>DotNet Developer</h5>
        </div>
      </div>
      <div className="Content">
        <h2 style={{ fontFamily: "Varela Round" }}>
          Position: Senior DotNet Developer
        </h2>
        <h3 style={{ fontFamily: "Varela Round" }}>Experience: 3+ Years</h3>

        <h4 style={{ fontFamily: "Varela Round" }}>The role involves:</h4>
        <ul style={{ fontFamily: "sans-serif" }}>
          <li>
            Understanding business requirements and architecting/designing an
            appropriate solution
          </li>
          <li>
            Building secure, scalable, high-performing, and testable software
          </li>
          <li>
            Taking ownership of delivery by working closely with junior members
          </li>
        </ul>

        <h4 style={{ fontFamily: "Varela Round" }}>Required Skills:</h4>
        <ul style={{ fontFamily: "sans-serif" }}>
          <li>
            Passion for building and delivering great software with a strong
            sense of ownership
          </li>
          <li>
            Defining all aspects of development from appropriate technology and
            workflow to coding standards
          </li>
          <li>
            Ensuring software meets quality, security, modifiability, and
            extensibility requirements
          </li>
          <li>Knowledge in containers</li>
          <li>Experience in application's design using .NET frameworks</li>
          <li>
            Solid knowledge using LINQ and Entity Framework (or other ORM)
          </li>
          <li>Experience with development and integration with RESTful APIs</li>
          <li>
            HTML 5, JavaScript, jQuery, MV* Frameworks (Angular/React, etc.)
          </li>
          <li>
            Knowledge in SQL Server, SQL Server Reporting Services (SSRS) or SQL
            Server Integration Services (SSIS), and SQL Server Agent
          </li>
          <li>Experience with ASP.NET, ASP.NET MVC, Web API with C#</li>
          <li>Coaching & guidance</li>
          <li>High precision on estimations</li>
          <li>Experience leading teams (mid-size teams +8)</li>
        </ul>

        <h4 style={{ fontFamily: "Varela Round" }}>Functional Competencies:</h4>
        <ul style={{ fontFamily: "sans-serif" }}>
          <li>Good problem-solving skills</li>
          <li>Excellent design, coding, and refactoring skills</li>
          <li>Good communication and presentation skills</li>
          <li>Awareness of latest IT, tech trends, and best practices</li>
          <li>Passion for technologies, products, and domains</li>
          <li>
            Ability to communicate complex technical ideas in a straightforward
            way
          </li>
          <li>
            Lateral thinker, providing simple innovative solutions to complex
            problems
          </li>
          <li>Ability to participate in multiple projects simultaneously</li>
          <li>
            Experience in large and complex database design and maintenance
          </li>
          <li>Experience in large-scale web applications</li>
          <li>
            Familiarity with SaaS product constructs and cloud technology
            concepts
          </li>
          <li>
            Familiarity with continuous integration and deployment practices
            (CI/CD)
          </li>
          <li>Quick learner and open to learning new technologies</li>
        </ul>
        <button onClick={handleApplyNow} className="custom-openings-button-1">
          Apply Now
        </button>
      </div>
      {showCard && (
        <div style={{ height: "500px", marginTop: "100px" }}>
          {formSubmitted ? (
            <div className="text-center">
              <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
                Thank you for applying!
              </h1>
              <p>
                Your job application has been submitted successfully. We'll
                review your information and get in touch with you soon.
              </p>
            </div>
          ) : (
            <Card
              className="horizontal-card"
              style={{ borderRadius: "40px", fontFamily: "Varela Round" }}
            >
              <CardBody>
                <div className="Heading">Apply For Job</div>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input
                        autoComplete="nope"
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        invalid={nameError !== ""}
                      />
                      <FormFeedback>{nameError}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="email">Email Address</Label>
                      <Input
                        autoComplete="nope"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        invalid={emailError !== ""}
                      />
                      <FormFeedback>{emailError}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="mobile">Mobile Number</Label>
                      <Input
                        autoComplete="nope"
                        type="text"
                        name="mobile"
                        id="mobile"
                        placeholder="Your mobile number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        invalid={mobileError !== ""}
                      />
                      <FormFeedback>{mobileError}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="linkedin">LinkedIn Profile</Label>
                      <Input
                        autoComplete="nope"
                        type="text"
                        name="linkedin"
                        id="linkedin"
                        placeholder="Your LinkedIn profile URL"
                        value={linkedin}
                        onChange={(e) => setLinkedin(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="cv">Upload your CV</Label>
                      <Input
                        autoComplete="nope"
                        type="file"
                        name="cv"
                        id="cv"
                        onChange={(e) => setCV(e.target.files[0])}
                        invalid={cvError !== ""}
                      />
                      <FormFeedback>{cvError}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  style={{ borderRadius: "40px" }}
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                  <ToastContainer />
                </Button>{" "}
                <Button
                  style={{ borderRadius: "40px" }}
                  color="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </CardBody>
            </Card>
          )}
        </div>
      )}

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default DotNetOpen;
