import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../ServiceCss/UXUI.css";
import service from "../assets/Services images/Uxui 1.0.svg";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import DoubleQuote1 from "../assets/Images/Double-quote-1.svg";
import DoubleQuote2 from "../assets/Images/Double-quote-2.svg";
import { Helmet } from "react-helmet";
import HorizontalMarquee from "../Components/HorizontalMarquee";
import ScrollButton from "../Components/ScrollButton";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const UXUI = ({ setProgress }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Ux/Ui Design</title>
        <meta name="description" content="Ux/Ui Services " />
      </Helmet>
      <div className="UXUI-background" style={{ fontFamily: "Varela Round" }}>
        <div className="image-container-UXUI">
          <img src={service} alt="Image" />
        </div>
        <div className="content-UXUI">
          <h2
            style={{
              fontWeight: "bolder",
              fontFamily: "Varela Round",
              fontSize: "30px",
            }}
          >
            UX/UI Design
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            Enhance user experiences with seamless design: where aesthetics and
            functionality merge to create digital excellence.
          </p>
          <button onClick={() => navigate("/contact")} className="about-button">
            Let's Connect <FaArrowRight />
          </button>
        </div>
      </div>

      <div className="Cloud-Cards">
        <div className="left-1">
          <div className="left-2">
            <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
              Crafting an Exceptional User Experience through UX/UI Design
            </h1>
            <br></br>

            <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>
              {" "}
              Our UX/UI design services are tailored to transform ordinary
              interactions into extraordinary experiences. We're on a mission to
              craft interfaces that not only look stunning but also function
              seamlessly, ensuring your users are not just customers but loyal
              advocates.
              <br></br>
              <br></br>
              With a team of passionate designers and a user-centric approach,
              we excel in creating designs that resonate with your target
              audience. From wireframes to the final pixel, we obsess over the
              details to create an intuitive and visually appealing journey for
              your users.
            </p>
          </div>
        </div>
        <div className="right-1">
          <div className="right-2">
            <img src={DoubleQuote1} />
            <br></br>
            <br></br>
            <p style={{ fontFamily: "sans-serif" }}>
              {" "}
              Their innovative approach has truly transformed our user
              experience and set a new standard for excellence in software
              design.
            </p>
            <br></br>
            <h4>- David </h4>
            <br></br>
            <img src={DoubleQuote2} />
          </div>
        </div>
      </div>

      <div className="cloud-background1" style={{ fontFamily: "Varela Round" }}>
        <div>
          <p className="Cloud-Quote">
            "Good design powers the digital world. Poor design results in
            inefficiency, customer attrition and low conversion rates."
          </p>
        </div>
      </div>

      <section style={{ marginTop: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <h2 className="text-dark" style={{ fontFamily: "Varela Round" }}>
                Our{" "}
              </h2>
              <h1 style={{ color: "#fd761b", fontFamily: "Varela Round" }}>
                Offerings <FaArrowRight />
              </h1>

              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                  <p style={{ fontFamily: "sans-serif" }}>
                    We specialize in delivering seamless UI and UX designs that
                    enhance the user experience.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4 ",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Wireframing</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Our wireframing service lays the foundation for
                      exceptional user interfaces. We specialize in creating
                      blueprints that showcase the structural design of your
                      digital product. With meticulous attention to user flow
                      and functionality, we bring clarity to your vision,
                      ensuring seamless navigation and easy interaction. Elevate
                      your user experience with wireframes that are not just
                      aesthetically pleasing, but also strategically effective.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong> Design Consulting</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Our design consulting service is your gateway to
                      innovative and impactful user experiences. We collaborate
                      closely with your team to bring out the full potential of
                      your project. Our seasoned design experts provide valuable
                      insights and data-driven recommendations. We help you make
                      informed design decisions, translating your ideas into
                      captivating visuals and intuitive interfaces. Harness the
                      collective power of creativity and strategy with our
                      design consulting service.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Customer Experience</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      At the core of our values is the belief that exceptional
                      customer experiences are the cornerstone of brand success.
                      We go beyond designing interfaces – we create immersive
                      journeys that resonate with your users, leaving a lasting
                      impact. Our tailored experiences inspire trust,
                      engagement, and unwavering loyalty. Let us transform each
                      interaction into a memorable moment, ensuring your users
                      become lifelong advocates of your brand.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong> Frontend Engineering</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      Transforming stunning designs into functional, seamless
                      interfaces is where our frontend engineering shines. We
                      bridge the gap between vision and reality with precision
                      and expertise. Our seasoned developers bring your designs
                      to life, ensuring compatibility across all devices and
                      browsers. We prioritize clean code and a smooth user
                      experience, making your digital product not just
                      beautiful, but also highly functional.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Service-Page-Work" data-background-title="Process">
        <div class="container container--column">
          <h1 class="section__title-wrap">
            <span class="section__subtitle">How do we work?</span>
          </h1>
          <div class="development-timeline">
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  User Research and Analysis
                </span>
                <p class="development-timeline__description">
                  Understand the audience through surveys, interviews, and
                  testing, creating user personas and identifying needs and
                  goals.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Information Architecture and Wireframing
                </span>
                <p class="development-timeline__description">
                  Organize content logically for an intuitive flow using
                  wireframes to visualize layout and structure.
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Prototyping and Interaction Design
                </span>
                <p class="development-timeline__description">
                  Create high-fidelity prototypes for user interaction, focusing
                  on a seamless user experience and engaging elements.
                </p>
              </div>
            </div>
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">Visual Design</span>
                <p class="development-timeline__description">
                  Design a visually appealing UI with color, typography, icons,
                  and imagery, maintaining a consistent look and feel with a
                  style guide.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Usability Testing and Iteration
                </span>
                <p class="development-timeline__description">
                  Gather user feedback, identify issues, and refine the design
                  iteratively until it aligns with user expectations and goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="background-cloud6">
        <h1
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Varela Round",
          }}
        >
          Technologies
        </h1>
        <p
          style={{
            textAlign: "center",
            alignItems: "center",
            fontSize: "20px",
            padding: "20px 50px 0",
            fontFamily: "sans-serif",
          }}
        >
          Our experts offers integrated services and our awesome product help
          you harness the power of technology. Streamline and automate IT.
          Enhance customer experiences, and drive exponential business growth.
        </p>

        {/* <MarqueeCarousel images={imageUrls} imageSize={imageSize} speed={speed} /> */}

        <div>
          <HorizontalMarquee />
        </div>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default UXUI;
