import React, { useEffect } from "react";
import Layout from "../Components/LayOut";
import "../ServiceCss/ResourceAugmentation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import service from "../assets/Services images/Resource Augumentation 1.0.svg";
import DoubleQuote1 from "../assets/Images/Double-quote-1.svg";
import DoubleQuote2 from "../assets/Images/Double-quote-2.svg";
import { Helmet } from "react-helmet";
import HorizontalMarquee from "../Components/HorizontalMarquee";
import ScrollButton from "../Components/ScrollButton";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const ResourceAugumentation = ({ setProgress }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Resource Augmentation</title>
        <meta name="description" content="Resource Augmentation Services" />
      </Helmet>
      <div
        className="Resource-background"
        style={{ fontFamily: "Varela Round" }}
      >
        <div className="image-container-Resource">
          <img src={service} alt="Image" />
        </div>
        <div className="content-Resource">
          <h2
            style={{
              fontWeight: "bolder",
              fontFamily: "Varela Round",
              fontSize: "30px",
            }}
          >
            Resource Augmentation
          </h2>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            Empower your team with talent enrichment: fueling your success with
            the right skills at the right time.
          </p>
          <button onClick={() => navigate("/contact")} className="about-button">
            Let's Connect <FaArrowRight />
          </button>
        </div>
      </div>

      <div className="Cloud-Cards">
        <div className="left-1">
          <div className="left-2">
            <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
              Fostering Agility, Enhancing Capabilities, and Fueling Sustainable
              Enterprise Growth.
            </h1>
            <br></br>

            <p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>
              {" "}
              We offers adaptability, allowing businesses to scale resources
              based on fluctuating demands and market dynamics.
              <br></br>
              <br></br>
              By fostering collaborative partnerships and focusing on core
              competencies, resource augmentation empowers businesses to
              concentrate on innovation and excellence. Its cost-effective
              nature ensures optimal utilization of resources, freeing
              organizations from the burden of traditional hiring costs. This
              strategic infusion of expertise not only optimizes operational
              processes but also propels enterprises toward sustainable growth.
            </p>
          </div>
        </div>
        <div className="right-1">
          <div className="right-2">
            <img src={DoubleQuote1} />
            <br></br>
            <br></br>
            <p style={{ fontFamily: "sans-serif" }}>
              {" "}
              Techryders' resource augmentation scaled our team with skilled
              professionals. We'll continue leveraging their resources.
            </p>
            <br></br>
            <h4>- Lon</h4>
            <br></br>
            <img src={DoubleQuote2} />
          </div>
        </div>
      </div>

      <div className="cloud-background1" style={{ fontFamily: "Varela Round" }}>
        <div>
          <p className="Cloud-Quote">
            "Ready-to-go and custom developer teams to boost your project."
          </p>
        </div>
      </div>

      <section style={{ marginTop: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <h2 className="text-dark" style={{ fontFamily: "Varela Round" }}>
                Our{" "}
              </h2>
              <h1 style={{ color: "#fd761b", fontFamily: "Varela Round" }}>
                Offerings <FaArrowRight />
              </h1>

              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                  <p style={{ fontFamily: "sans-serif" }}>
                    Our IT staff augmentation services offer a seamless balance
                    between project advancement and cost efficiency. Scale your
                    team flexibly according to your current development
                    requirements.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4 ",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Add Team in Your Existing Project</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Augment your existing team with the extra talent to
                      deliver a high-quality project within your timeline.
                      Leverage our IT team augmentation services to build custom
                      teams with a diverse set of skills to cover your business
                      needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      backgroundColor: "#f8c0f4",
                      borderRadius: "0px 0px 40px",
                    }}
                  >
                    <p
                      className="text-black mt-3"
                      style={{ fontFamily: "Varela Round" }}
                    >
                      <strong>Vendor Transition</strong>
                    </p>
                    <p
                      className="text-black card-p1"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Looking for a new technology provider to launch your
                      solution? We assist you in a smooth and effective vendor
                      transition and take over your project in the middle of
                      your journey.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Short-Term Staff Augmentation</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {" "}
                      Leverage this hiring model for urgent hiring of tech
                      talent. Along with ready-to-go teams, we have a candidate
                      base of top talent that can take on the job within a week.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderRadius: "0px 0px 40px",
                      boxShadow: "rgba(33, 30, 109, 0.18) 0px 4px 24px",
                    }}
                  >
                    <p
                      className="mt-3"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "Varela Round",
                      }}
                    >
                      <strong>Long-Term Extended Team</strong>
                    </p>
                    <p
                      className="text-blue card-p1"
                      style={{
                        color: "rgb(33, 30, 109)",
                        fontFamily: "sans-serif",
                      }}
                    >
                      We offer custom recruitment for stable cooperation - you
                      get a developer hired with the required hard and soft
                      skills. You or your project manager can supervise task
                      completion, while we shoulder the rest.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="Service-Page-Work" data-background-title="Process">
        <div class="container container--column">
          <h1 class="section__title-wrap">
            <span class="section__subtitle">How do we work?</span>
          </h1>
          <div class="development-timeline">
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Recruitment and HR interviews
                </span>
                <p class="development-timeline__description">
                  We contacts candidates from both our internal resources and
                  the open market. We schedule a call after getting the CV, to
                  evaluate their soft skills and discuss previous experience,
                  mostly assessing the candidate’s overall competence.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Technical interview and skill assessment
                </span>
                <p class="development-timeline__description">
                  We assess candidates' technology knowledge and architectural
                  understanding, customizing our interview strategies to match
                  the specific tech stack. Our technical interviews cover both
                  theoretical and practical aspects, and may include live coding
                  tests, to provide a thorough evaluation of candidates' skills
                  and abilities.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Managerial interview
                </span>
                <p class="development-timeline__description">
                  This represents the concluding phase of our candidate
                  evaluation process. We evaluate their suitability for
                  projects, leadership potential, teamwork capabilities, and
                  engage in discussions about their career prospects.{" "}
                </p>
              </div>
            </div>
            <div class="development-timeline__row">
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Legal &amp; Background Check, Security Measures
                </span>
                <p class="development-timeline__description">
                  Our company prioritizes legal and security aspects when
                  forming a dedicated team. We begin with comprehensive legal
                  analysis and contract drafting, encompassing security
                  requirements and professional background checks. On a
                  technical front, we establish secure physical and IT
                  infrastructure, ensuring access control and cybersecurity to
                  align with data protection regulations.{" "}
                </p>
              </div>
              <div class="development-timeline__item">
                <div class="development-timeline__round">
                  <i class="icon"></i>
                </div>
                <span class="development-timeline__title">
                  Presenting a candidate to you
                </span>
                <p class="development-timeline__description">
                  Whether delivering a candidate to the client or forming a
                  dedicated team, we provide additional details for evaluating
                  their hard and soft skills.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="background-cloud6" style={{ fontFamily: "Varela Round" }}>
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Technologies
        </h1>
        <p
          style={{
            textAlign: "center",
            alignItems: "center",
            fontSize: "20px",
            padding: "20px 50px 0",
            fontFamily: "sans-serif",
          }}
        >
          Our experts offers integrated services and our awesome product help
          you harness the power of technology. Streamline and automate IT.
          Enhance customer experiences, and drive exponential business growth.
        </p>

        {/* <MarqueeCarousel images={imageUrls} imageSize={imageSize} speed={speed} /> */}

        <div>
          <HorizontalMarquee />
        </div>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default ResourceAugumentation;
