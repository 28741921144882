import React, { useEffect, useRef } from "react";
import Layout from "../Components/LayOut";
import "../Css/Careers.css";
import { Link, useNavigate } from "react-router-dom";
import image1 from "../assets/Images/Career1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import ScrollButton from "../Components/ScrollButton";
import { FaArrowRight } from "react-icons/fa";
import one from "../assets/CareerImages/1.svg";
import two from "../assets/CareerImages/2.svg";
import three from "../assets/CareerImages/3.svg";
import four from "../assets/CareerImages/4.svg";
import five from "../assets/CareerImages/5.svg";
import six from "../assets/CareerImages/6.svg";
import idea from "../assets/CareerImages/idea.svg";
import together from "../assets/CareerImages/Together.svg";
import joy from "../assets/CareerImages/Joy.svg";
import { Helmet } from "react-helmet";
import HashTag from "../Components/HashTag";
import Suggestion from "../Components/Suggestion";

const Careers = ({ setProgress }) => {
  const navigate = useNavigate();

  const openingsRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Careers</title>
        <meta name="description" content="Explore Jobs Openings " />
      </Helmet>
      <div className="Careers-background">
        <div className="image-container-Careers">
          <img src={image1} alt="Image" />
        </div>
        <div className="content-Careers">
          <h2
            style={{
              fontWeight: "bolder",
              fontFamily: "Varela Round",
              fontSize: "30px",
              color: "white",
            }}
          >
            Build Your Future With Us
          </h2>
          <p
            style={{
              fontFamily: "sans-serif",
              fontSize: "18px",
              color: "white",
            }}
          >
            Watching the world of next-tech unfold? It's time to be part of it.
            Explore challenging and exciting opportunities with us.
          </p>
        </div>
      </div>

      <div className="background-career-8" data-aos="fade-up">
        <div>
          <h1 style={{ fontFamily: "Varela Round", fontWeight: "bold" }}>
            Our Work Culture.
          </h1>
          <p style={{ fontFamily: "sans-serif", fontSize: "18px" }}>
            We have created a work environment where Techians feel encouraged,
            heard, and supported. We cherish inclusivity & diversity at our
            workplace where everyone has an equal opportunity to grow.
          </p>
        </div>
        <div className="Work-Cards" data-aos="fade-down">
          <div className="Work1">
            <img src={joy}></img>
            <h3 style={{ fontFamily: "Varela Round" }}>
              Find joy in your work.
            </h3>
            <p style={{ fontFamily: "sans-serif" }}>
              We've fostered a culture that infuses light-heartedness and
              enjoyment into our work. We commemorate significant occasions,
              organize offsite trips, and host engaging events year-round. Our
              satisfaction stems from creating solutions that we take pride in.
            </p>
          </div>
          <div className="Work1">
            <img src={idea}></img>
            <h3 style={{ fontFamily: "Varela Round" }}>Come Up With Ideas</h3>
            <p style={{ fontFamily: "sans-serif" }}>
              We are a haven for nurturing ideas. We endeavor to establish an
              environment where our community can express their thoughts freely
              and confidently, while remaining humble and receptive to fresh
              ideas.
            </p>
          </div>
          <div className="Work1">
            <img src={together}></img>
            <h3 style={{ fontFamily: "Varela Round" }}>Flourish Together</h3>
            <p style={{ fontFamily: "sans-serif" }}>
              We've established an inclusive space where individuals from
              diverse backgrounds can unite and experience a profound sense of
              belonging. We make dedicated efforts to uphold inclusivity,
              integrity, and mutual respect both within our community and among
              our leadership.
            </p>
          </div>
        </div>
      </div>

      <section class="looking-perks " data-aos="fade-up">
        <div class="inner-wrap">
          <div class="container-fluid">
            <div class="container big-container">
              <div class="row">
                <div class="col-lg-12 ">
                  <div
                    class="content-wrap text-center"
                    style={{ paddingBottom: "20px" }}
                  >
                    <h1
                      style={{ fontFamily: "Varela Round", fontWeight: "bold" }}
                    >
                      Perks Of Becoming A Techians.
                    </h1>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="looking-perks-box">
                    <img src={one} />
                    <p>
                      Opportunities for career growth in emerging technologies
                      through learning and development
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="looking-perks-box">
                    <img src={three} />
                    <p>
                      Attractive compensation and significant avenues for
                      personal and professional growth.
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="looking-perks-box">
                    <img src={two} />
                    <p>
                      Join a tight-knit community of dedicated developers,
                      designers, and marketers.
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="looking-perks-box">
                    <img src={six} />
                    <p>
                      Appraisals based on performance, coupled with quarterly
                      rewards and recognition.
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="looking-perks-box">
                    <img src={four} />
                    <p>
                      Comprehensive health and wellness benefits alongside
                      tax-saving policies focused on employees' needs.
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="looking-perks-box">
                    <img src={five} />
                    <p>
                      Convenient office facilities in close proximity to local
                      markets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="Openings"
        ref={openingsRef}
        style={{ fontFamily: "Varela Round" }}
      >
        <h1 style={{ fontWeight: "bold" }}>Explore Opportunities.</h1>

        <div className="hrContainer">
          <hr className="centeredHr" />
        </div>
        <table className="spacedTable">
          <thead>
            <tr>
              <th>Position</th>
              <th>Required Experience</th>
              <th>Apply</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Java Developer</td>
              <td>3+ years</td>
              <td>
                <Link to="/apply/java">
                  <button className="custom-button-Career">View Details</button>{" "}
                </Link>
              </td>
            </tr>
            <tr>
              <td>Dot Net Developer</td>
              <td>2+ years</td>
              <td>
                <Link to="/apply/DotNet">
                  <button className="custom-button-Career">View Details</button>
                </Link>
              </td>
            </tr>
            <tr>
              <td>React Developer</td>
              <td>3+ years</td>
              <td>
                <Link to="/apply/React">
                  <button className="custom-button-Career">View Details</button>{" "}
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="background-about-2 ">
        <Suggestion></Suggestion>
      </div>

      <div className="background-careers-2">
        <HashTag></HashTag>
      </div>
      <ScrollButton />
    </Layout>
  );
};

export default Careers;
