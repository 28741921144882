import React, { useEffect, useState, useRef } from "react";
import "../Css/Home.css";
import LayOut from "../Components/LayOut";
import service1 from "../assets/Services images/Cloud 1.0.svg";
import service2 from "../assets/Services images/Data Analytics.svg";
import service3 from "../assets/Services images/EnterPrise 1.0.svg";
import service4 from "../assets/Services images/MobileDev 1.0.svg";
import service5 from "../assets/Services images/ProductDevlopment 1.0.svg";
import service6 from "../assets/Services images/QA 1.0.svg";
import service7 from "../assets/Services images/Uxui 1.0.svg";
import service8 from "../assets/Services images/Resource Augumentation 1.0.svg";
import letdiscuss from "../assets/Images/LetDiscuss.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image4 from "../assets/Images/Home9.png";
import image6 from "../assets/Images/Home12.svg";
import Typed from "typed.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Css/TypeEffect.css";
import { Form, FormGroup, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa"; // Import the FontAwesome icons
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import slide1 from "../assets/Shapes/Digital.png";
import slide2 from "../assets/Shapes/Experties.svg";
import slide3 from "../assets/Shapes/Scalable.png";
import slide4 from "../assets/Shapes/Intigrity.png";
import ScrollButton from "../Components/ScrollButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Build from "../assets/Images/Build.svg";
import Manage from "../assets/Images/Manage.svg";
import Design from "../assets/Images/Design.svg";
import Slider from "react-slick";
import slideimage1 from "../assets/Home Images/img_1.jpg";
import slideimage2 from "../assets/Home Images/img_2.jpg";
import slideimage3 from "../assets/Home Images/img_3.jpg";
import slideimage4 from "../assets/Home Images/img_4.jpg";
import slideimage5 from "../assets/Home Images/img_5.jpg";
import slideimage6 from "../assets/Home Images/img_6.jpg";
import { Helmet } from "react-helmet";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

const Home = ({ setProgress }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setProgress(20);
    setTimeout(() => {
      setProgress(100);
    }, 1000);
  }, []);

  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    arrows: false,

    cssEase: "ease-in-out",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,

          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [messageError, setMessageError] = useState("");
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    // Reset error messages
    setNameError("");
    setEmailError("");
    setPhoneError("");
    setMessageError("");

    // Validation logic
    let hasError = false;

    if (!name.trim()) {
      setNameError("Name is required");
      hasError = true;
    }

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!email.trim()) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
      hasError = true;
    }

    const phoneRegex = /^\d{10}$/;
    if (!phone.trim()) {
      setPhoneError("Mobile No is required");
      hasError = true;
    } else if (!phoneRegex.test(phone)) {
      setPhoneError("Invalid phone number");
      hasError = true;
    }

    if (!message.trim()) {
      setMessageError("Please specify the help needed");
      hasError = true;
    }

    // If there are validation errors, return early
    if (hasError) {
      return;
    }

    try {
      const response = await fetch("https://techryders.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, phone, message }),
      });

      if (response.status === 200) {
        // Handle success on the frontend
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setFormSubmitted(true);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [active, setActive] = useState(0);

  const slides = [
    {
      heading: "Integrity",
      image: slide4,
      description:
        "At TECHRYDERS, integrity is our cornerstone. We operate with complete transparency, ensuring honest communication and reliability in every project. With a client-focused approach, we exceed expectations, building enduring partnerships rooted in trust. Choose us for IT solutions driven by integrity and designed for success.",
      height: "20vw",
      width: "20vw",
    },
    {
      heading: "Digital Innovation",
      image: slide1,
      description:
        "Discover the power of innovation with TECHRYDERS. We are not just tech experts we are pioneers of innovation. Our solutions are born from creativity and expertise, designed to transform challenges into opportunities. Choose us for groundbreaking IT solutions that redefine what is possible propelling your business into the digital frontier.",
      height: "20vw",
      width: "20vw",
    },

    {
      heading: "Unmatched Expertise",
      image: slide2,
      description:
        "We bring unparalleled expertise and years of hands-on experience to the table. Our skilled professionals have a proven track record of solving complex challenges, ensuring seamless project execution. Partner with us for reliable IT solutions crafted by experts, backed by a legacy of successful implementations. Elevate your business with our wealth of knowledge and proficiency.",
      height: "20vw",
      width: "20vw",
    },
    {
      heading: "Scalability",
      image: slide3,
      description:
        "Grow your business confidently with TECHRYDERS. Our solutions are not just powerful; they are scalable. Whether you are a startup or an enterprise, we offer adaptable IT solutions that expand with your needs. Experience the freedom of seamless scalability, ensuring your technology evolves as your business flourishes. Choose us for limitless possibilities and a future-ready approach to IT solutions.",
      height: "15vw",
      width: "20vw",
    },
  ];

  const carouselContent = [
    {
      id: 1,
      title: "- Albert",
      description:
        "Techryders has been a pleasure to deal with over some years. Their quality of work, professionalism, knowledge, and follow-through have impressed me from day one. Their developer is very responsive and quick to address questions. I would highly recommend Techryders.",
    },
    {
      id: 2,
      title: "- Micquel",
      description:
        "Working with Techryders was a fantastic experience for us. Using innovative methods, they provided excellent solutions. They all completed their work on schedule and with good quality. It was a pleasure working with the team.",
    },
    {
      id: 3,
      title: "- William",
      description:
        "I chose Techryders because of its easy-to-use interface and its robust features that enabled us to handle huge volumes of data quickly and effectively. I will do business again with them.",
    },
    {
      id: 4,
      title: "- Yannick",
      description:
        "Techryders has been a real asset to our IT department - a true extension. They have all rolled up their sleeves to meet each challenge and opportunity as it arises, and we simply could not have done it without them.",
    },
  ];

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [slides.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevIndex) => {
        if (prevIndex === carouselContent.length - 1) {
          // If the current slide is the last one, set the active index to 0
          return 0;
        } else {
          // Otherwise, increment the active index by 1
          return prevIndex + 1;
        }
      });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [carouselContent.length]);

  const serviceData = [
    {
      title: "Cloud",
      image: service1,
      width: "80%",
      height: "60%",
    },
    {
      title: "Data And Analytics",
      image: service2,
      width: "80%",
      height: "60%",
    },
    {
      title: "Enterprise Solutions",
      image: service3,
      width: "80%",
      height: "60%",
    },
    {
      title: "Mobile Development",
      image: service4,
      width: "90%",
      height: "80%",
    },
    {
      title: "Product Development",
      image: service5,
      width: "80%",
      height: "60%",
    },
    {
      title: "Resource Augmentation",
      image: service8,
      width: "80%",
      height: "60%",
    },
    {
      title: "Testing/QA",
      image: service6,
      width: "60%",
      height: "30%",
    },
    {
      title: "UX/UI Design",
      image: service7,
      width: "80%",
      height: "60%",
    },
    // Add more service data with titles and image URLs
  ];

  const [serviceIndex, setServiceIndex] = useState(0); // Active service index
  const [typingText, setTypingText] = useState(""); // Current text for typing effect

  // Create a ref for the Typed.js instance
  const typedRef = useRef(null);

  useEffect(() => {
    const options = {
      strings: [serviceData[serviceIndex].title],
      typeSpeed: 100, // Typing speed in milliseconds
      backSpeed: 200, // Backspacing speed in milliseconds

      cursorChar: "",
      onComplete: () => {
        setTimeout(() => {
          // After typing is complete, change to the next service and image
          setServiceIndex((prevIndex) => (prevIndex + 1) % serviceData.length);
        }, 2000); // Change service and image after 2 seconds (adjust as needed)
      },
    };

    // Initialize the Typed instance
    typedRef.current = new Typed(".typed-element", options);

    // Clean up the Typed instance when the component unmounts
    return () => {
      typedRef.current.destroy();
    };
  }, [serviceIndex]);

  useEffect(() => {
    // Listen for changes in the active service and update the typing text
    setTypingText(serviceData[serviceIndex].title);

    // Clear the typing text when switching services to start fresh
    typedRef.current.reset();
  }, [serviceIndex]);

  const handleback = () => {
    setFormSubmitted(false);
  };

  return (
    <LayOut>
      <Helmet>
        <title>Techryders</title>
        <meta
          name="description"
          content="Techryders Home Page IT Solution Services"
        />
        <meta
          name="keywords"
          content="Techryders ,Techryders services, Techryders Cloud solution, Data And Analytics Techryders ,Cloud, Data Analytics, Techryders Prouducts,  Enterpise Solution, Mobile App Development, Product Development, Resource Augmentation, Testing, QA, Ux/Ui,ITServices,  "
        />
      </Helmet>

      <div className="home-image-1 background-home-1">
        <div className="flex-container1">
          <div>
            <h3
              style={{
                color: "#ff7300",
                fontFamily: "Varela Round, sans-serif",
              }}
            >
              ONE PLACE SOLUTION FOR
            </h3>
            <p
              style={{
                color: "white",
                fontFamily: "Varela Round, sans-serif",
                fontSize: " 50px",
              }}
            >
              Consulting & Software Development For <br />
              <span className="typed-element"></span>
            </p>
          </div>

          <button onClick={() => navigate("/contact")} className="about-button">
            Let's Connect <FaArrowRight />
          </button>
        </div>

        <div className="flex-1">
          <div className="home-carousel-services">
            {serviceData.map((service, index) => (
              <div
                key={service.title}
                className={`home-services ${
                  serviceIndex === index ? "active" : ""
                }`}
              >
                <div className="slide-content-services">
                  {serviceIndex === index && (
                    <img
                      src={service.image}
                      alt={service.title}
                      style={{ width: service.width, height: service.height }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="home-image-2 background-home-2 ">
        <div>
          <h1 className="why-choose-us">Why</h1>
          <h2 className="why-choose-us">Techryders Solution ?</h2>

          <div className="home-carousel-container">
            <div className="home-carousel-navigation">
              {slides.map((slide, index) => (
                <button
                  key={slide.heading}
                  className={`nav-item ${
                    activeIndex === index ? "active" : ""
                  }`}
                  onClick={() => handleClick(index)}
                  style={{
                    fontSize: "15vh",
                    fontWeight: "bolder",
                    fontFamily: "Varela Round",
                  }}
                >
                  {slide.heading}
                </button>
              ))}
            </div>

            <div className="home-carousel-slides">
              {slides.map((slide, index) => (
                <div
                  key={slide.heading}
                  className={`home-slide ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  <div className="slide-content">
                    <div className="slide-image">
                      <img
                        src={slide.image}
                        alt={slide.heading}
                        style={{
                          height: slide.height,
                          width: slide.width,
                        }}
                      />
                    </div>
                    <div className="slide-text">
                      <h2>{slide.heading}</h2>
                      <p>{slide.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="home-image-1 background-home-3 ">
        <div className="flex-3">
          <h1 className="heading-large-1" style={{ color: "white" }}>
            Explore Our Offerings
          </h1>
          <div className="white-text">
            <div>
              <div className="heading-large">
                <Link to="/services/cloud" className="arrow-icon white-link">
                  Cloud <ArrowOutwardIcon />
                </Link>
              </div>
              <div className="heading-large">
                <Link
                  to="/services/data-analytics"
                  className="arrow-icon white-link"
                >
                  Data And Analytics <ArrowOutwardIcon />
                </Link>
              </div>
              <div className="  heading-large">
                <Link
                  to="/services/enterprise-solutions"
                  className="arrow-icon white-link"
                >
                  Enterprise Solution <ArrowOutwardIcon />
                </Link>
              </div>
              <div className="  heading-large">
                <Link
                  to="/services/mobile-app-development"
                  className="arrow-icon white-link"
                >
                  Mobile Application Development <ArrowOutwardIcon />
                </Link>
              </div>
              <div className="  heading-large">
                <Link
                  to="/services/ProductDevelopment"
                  className="arrow-icon white-link"
                >
                  Product Development <ArrowOutwardIcon />
                </Link>
              </div>
              <div className="  heading-large">
                <Link
                  to="/services/resource-augmentation"
                  className="arrow-icon white-link"
                >
                  Resource Augmentation <ArrowOutwardIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-container-3">
          <img src={image4} alt="Image" className="image-home-3" />
        </div>
      </div>

      <div className="home-image-2 background-home-4 ">
        <h1
          style={{
            fontFamily: "Varela Round",
            color: "white",
            fontWeight: "bold",
          }}
        >
          Our Products
        </h1>

        <div className="product-home">
          <div className="product-home-card" data-aos="fade-down">
            <h2
              style={{
                fontFamily: "Varela Round",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Inventocart
            </h2>
            <p
              style={{
                fontFamily: "sans-serif",
                marginTop: "20px",
                color: "white",
                fontSize: "20px",
                padding: "10px",
              }}
            >
              Revolutionize your business operations with our cutting-edge
              integrated systems tailored for production-based or distribution
              businesses. Experience streamlined efficiency and enhanced
              productivity like never before!
            </p>
            <button onClick={() => navigate("/Erp")} class="about-button">
              Know More <FaArrowRight />
            </button>
          </div>
          <div className="product-home-card" data-aos="fade-down">
            <h2
              style={{
                fontFamily: "Varela Round",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Cloud Data Quality
            </h2>
            <p
              style={{
                fontFamily: "sans-serif",
                marginTop: "20px",
                color: "white",
                fontSize: "20px",
                padding: "10px",
              }}
            >
              Unlock the Full Potential of Your Data with Our Cloud-Powered Data
              Quality Framework: Rapid Testing, Seamless Customization, and
              Real-Time Insights for Informed Decision-Making.
            </p>
            <button onClick={() => navigate("/Etl")} class="about-button">
              Know More <FaArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className="home-image-1 background-home-8 ">
        <h1
          style={{
            color: "white",
            fontFamily: "Varela Round",
            marginBottom: "50px",
            fontWeight: "bold",
          }}
        >
          Revolutionize business with cutting-edge cloud and data solutions
        </h1>

        <Slider {...settings}>
          <div className="CircleCarousel">
            <img className="CarouselImages" src={slideimage1} />
            <p className="CircleCarouselName">Airline</p>
          </div>
          <div className="CircleCarousel">
            <img className="CarouselImages" src={slideimage2} />
            <p className="CircleCarouselName">Healthcare</p>
          </div>
          <div className="CircleCarousel">
            <img className="CarouselImages" src={slideimage3} />
            <p className="CircleCarouselName">Technology</p>
          </div>
          <div className="CircleCarousel">
            <img className="CarouselImages" src={slideimage4} />
            <p className="CircleCarouselName">FinTech</p>
          </div>
          <div className="CircleCarousel">
            <img className="CarouselImages" src={slideimage5} />
            <p className="CircleCarouselName">Education</p>
          </div>
          <div className="CircleCarousel">
            <img className="CarouselImages" src={slideimage6} />
            <p className="CircleCarouselName">Telecom</p>
          </div>
        </Slider>
      </div>

      <div className="home-image-2 background-home-6">
        <h1 style={{ fontWeight: "bold" }}>
          We add value to all phases of the
        </h1>
        <br></br>
        <h1 style={{ fontWeight: "bold" }}>digital lifecycle</h1>

        <div className="myDIV" data-aos="fade-down">
          <div>
            <h3 style={{ color: "white", fontWeight: "bold" }}>DESIGN</h3>
            <p style={{ fontFamily: "sans-serif" }}>
              Evaluate & experiment to figure out <br></br> the right thing to
              make
            </p>
            <img src={Design}></img>
          </div>
          <div>
            <h3 style={{ color: "white", fontWeight: "bold" }}>BUILD</h3>
            <p style={{ fontFamily: "sans-serif" }}>
              Launch & grow <br></br> working software
            </p>
            <img src={Build}></img>
          </div>

          <div>
            <h3 style={{ color: "white", fontWeight: "bold" }}>MANAGE</h3>
            <p style={{ fontFamily: "sans-serif" }}>
              Create & maintain a <br></br> lasting business
            </p>
            <img src={Manage}></img>
          </div>
        </div>
      </div>

      <div className="home-image-1 background-home-5">
        <div className="flex-carousel5">
          <h1
            style={{
              color: "white",
              fontFamily: "Varela Round",
              marginBottom: "50px",
              fontWeight: "bold",
            }}
          >
            What Our Clients Say
          </h1>
          <div className="home-carousel-container-1">
            <div className="home-carousel-slides-1">
              {carouselContent.map((slide, index) => (
                <div
                  key={slide.heading}
                  className={`home-slide-1 ${active === index ? "active" : ""}`}
                >
                  <div className="slide-text-1">
                    <p style={{ fontSize: "19px", fontFamily: "sans-serif" }}>
                      {slide.description}
                    </p>
                    <h4 style={{ fontFamily: "sans-serif" }}>{slide.title}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex-container-5">
          <img src={image6} alt="Image" className="image-home-5" />
        </div>
      </div>

      <div className="home-image-2 background-home-7">
        {formSubmitted ? (
          <div className="flex-7">
            <h1
              style={{
                color: "white",
                fontFamily: "Varela Round",
                fontWeight: "bold",
              }}
            >
              Thanks for reaching out!
            </h1>
            <p
              style={{
                color: "white",
                fontFamily: "sans-serif",
                fontSize: "20px",
              }}
            >
              Our support team has received your message. Someone will get back
              to you shortly.
            </p>
            <button className="about-button" onClick={handleback}>
              <FontAwesomeIcon icon={faArrowLeftLong} /> Back
            </button>
          </div>
        ) : (
          <>
            <div className="flex-7">
              <h1 className="white-text heading-large-1">Let's Discuss</h1>
              <Form onSubmit={onSubmit}>
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    autoComplete="nope"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="underline-input"
                  />
                  {nameError && <div style={{ color: "red" }}>{nameError}</div>}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    autoComplete="nope"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="underline-input"
                  />
                  {emailError && (
                    <div style={{ color: "red" }}>{emailError}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="nope"
                    placeholder="Mobile No"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="underline-input"
                  />
                  {phoneError && (
                    <div style={{ color: "red" }}>{phoneError}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    name="message"
                    id="message"
                    autoComplete="nope"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="underline-input"
                  />
                  {messageError && (
                    <div style={{ color: "red" }}>{messageError}</div>
                  )}
                </FormGroup>

                <button type="submit" className="custom-button-3">
                  Submit
                </button>
                <ToastContainer />
              </Form>
            </div>
            <div className="flex-container-7">
              <img src={letdiscuss} alt="Image" className="image-home-7" />
            </div>
          </>
        )}
      </div>
      <ScrollButton />
    </LayOut>
  );
};

export default Home;
