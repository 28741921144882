import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Home from "../src/Pages/Home";
import AboutUs from "../src/Pages/AboutUs";
import Careers from "../src/Pages/Careers";
import { Helmet } from "react-helmet";
import OurProducts from "../src/Pages/OurProducts";
import ContactUs from "../src/Pages/ContactUs";
import Cloud from "../src/Services/Cloud";
import DataAndAnalytics from "../src/Services/DataAndAnalytics";
import EnterpriseSolution from "../src/Services/EnterpriseSolution";
import MobileAppDev from "../src/Services/MobileAppDev";
import QATesting from "../src/Services/QATesting";
import ResourceAugumentation from "../src/Services/ResourceAugumentation";
import UXUI from "../src/Services/UXUI";
import Services from "../src/Pages/Services";
import JavaOpen from "../src/Openings/JavaOpen";
import DotNetOpen from "../src/Openings/DotNetOpen";
import ReactOpen from "../src/Openings/ReactOpen";
import { useEffect, useState } from "react";
import Erp from "../src/ProductPages/Erp";
import Etl from "../src/ProductPages/Etl";
import LearnHome from "../src/LearnInfinity/Pages/LearnHome";

import LoadingBar from "react-top-loading-bar";

import ProductDevelopment from "../src/Services/ProductDevelopment";
import Blog from "./Pages/Blog";
import ScrollButton from "./Components/ScrollButton";

function App() {
  useEffect(() => {
    AOS.init({ duration: 500, offset: 150, once: false, mirror: true });
    AOS.refreshHard();
  }, []);

  const [progress, setProgress] = useState(0);

  return (
    <HashRouter>
      <Helmet>
        <title>Techryders</title>
        <meta name="description" content="One Stop Solution For IT services" />
        <meta
          name="keywords"
          content="Techryders,Techryders services, Techryders Cloud solution, Techryders Prouducts, Data And Analytics Techryders ,Cloud, Data Analytics, Enterpise Solution, Mobile App Development, Product Development, Resource Augmentation, Testing, QA, Ux/Ui"
        />
      </Helmet>

      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
        waitingTime={1000}
      />

      <Routes>
        <Route path="/" element={<Home setProgress={setProgress} />} />
        <Route
          path="/services"
          element={<Services setProgress={setProgress} />}
        />
        <Route path="/about" element={<AboutUs setProgress={setProgress} />} />
        <Route
          path="/careers"
          element={<Careers setProgress={setProgress} />}
        />
        <Route
          path="/ourproduct"
          element={<OurProducts setProgress={setProgress} />}
        />
        <Route
          path="/contact"
          element={<ContactUs setProgress={setProgress} />}
        />
        <Route
          path="/services/cloud"
          element={<Cloud setProgress={setProgress} />}
        />

        <Route
          path="/services/ProductDevelopment"
          element={<ProductDevelopment setProgress={setProgress} />}
        />
        <Route
          path="/services/data-analytics"
          element={<DataAndAnalytics setProgress={setProgress} />}
        />
        <Route
          path="/services/enterprise-solutions"
          element={<EnterpriseSolution setProgress={setProgress} />}
        />
        <Route
          path="/services/mobile-app-development"
          element={<MobileAppDev setProgress={setProgress} />}
        />
        <Route
          path="/services/qa-testing"
          element={<QATesting setProgress={setProgress} />}
        />
        <Route
          path="/services/resource-augmentation"
          element={<ResourceAugumentation setProgress={setProgress} />}
        />
        <Route
          path="/services/ux-ui-design"
          element={<UXUI setProgress={setProgress} />}
        />
        <Route
          path="/apply/java"
          element={<JavaOpen setProgress={setProgress} />}
        />
        <Route
          path="/apply/DotNet"
          element={<DotNetOpen setProgress={setProgress} />}
        />
        <Route
          path="/apply/React"
          element={<ReactOpen setProgress={setProgress} />}
        />
        <Route path="/Erp" element={<Erp setProgress={setProgress} />} />
        <Route path="/Etl" element={<Etl setProgress={setProgress} />} />
        <Route path="/Blog" element={<Blog setProgress={setProgress} />} />
        <Route
          path="/Learn-IT"
          element={<LearnHome setProgress={setProgress} />}
        />
      </Routes>
    </HashRouter>
  );
}

export default App;
